import { AbstractMessageHandler } from "@/services/rest/messages/message-handler";
import { XmlRequest } from "@/services/rest/xml-request";
import { getRequiredAttribute, getRequiredChild } from "@/utils/xml-helper-functions";
import { LocalizedError } from "@/services/rest/errors";

export class ACt11SendFileMessageHandler extends AbstractMessageHandler<void> {
    protected readonly requestType: string = "A.CT.1.1";
    constructor (readonly fileEncoded: string, readonly comment: string,
                 readonly name: string, readonly contentType: string, readonly size: number) {
        super();
    }

    buildRequest (request: XmlRequest) {
        const actionElement = request.addChild(request.body, "action", {
            type: this.requestType
        });
        request.addChildWithValue(actionElement, "comment", this.comment);
        request.addChildWithValue(actionElement, "name", this.name);
        request.addChildWithValue(actionElement, "contentType", this.contentType);
        request.addChildWithValue(actionElement, "size", this.size.toString());
        request.addCdata(request.addChild(actionElement, "fileEncoded"), this.fileEncoded);
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    parseMessageBody (body: Element, _head: Element) {
        const action = getRequiredChild(body, "action");
        const serverCode = getRequiredAttribute(action, "servercode");

        if (serverCode !== "4330") {
            throw new LocalizedError(`errors.mtl.code${serverCode}`);
        }
    }
}

import { AbstractMessageHandler } from "@/services/rest/messages/message-handler";
import { XmlRequest } from "@/services/rest/xml-request";
import { getChild, getRequiredAttribute, getRequiredChild } from "@/utils/xml-helper-functions";
import { LocalizedError } from "@/services/rest/errors";
import { DocStatus, UserDocument, UserContent } from "@/model/user-content";

export class QUs111UserContent extends AbstractMessageHandler<UserContent> {
    protected readonly requestType: string = "Q.US.1.11";

    constructor () {
        super();
    }

    buildRequest (request: XmlRequest) {
        const actionElement = request.addChild(request.body, "query", {
            type: this.requestType
        });
        const selectorElement = request.addChild(actionElement, "Selector");
        request.addChild(selectorElement, "SelectObject", {
            class: "ps.user.User",
            allFields: "true"
        });
        request.addChild(selectorElement, "SelectObject", {
            class: "ps.user.UserContent",
            allFields: "true"
        });
    }
    // eslint-disable-next-line
    parseMessageBody (body: Element, _head: Element): UserContent {
        const action = getRequiredChild(body, "query");
        const serverCode = getRequiredAttribute(action, "servercode");
        if (serverCode === "1190") {
            const userContent: UserDocument[] = [];
            const contentNode = getChild(getRequiredChild(action, "US"), "UserContentList");
            if (contentNode) {
                for (let ucElement: Element | null = contentNode.firstElementChild; ucElement !== null; ucElement = ucElement?.nextElementSibling) {
                    userContent.push({
                        docType: getRequiredAttribute(ucElement, "comment"),
                        status: getRequiredAttribute(ucElement, "status") as unknown as DocStatus
                    }
                    );
                }
            }
            return {
                userContent
            };
        }
        throw new LocalizedError(`errors.mtl.qUs111.code${serverCode}`);
    }
}

<template>
    <div class="halls">
        <div class="halls-items">
            <div v-for="(hall, index) in halls" :key="index" class="item" :class="{ disabled: hall.disabled }">
                <h2>«{{ hall.name }}»</h2>
                <div class="img">
                    <img :src="`/halls/${index + 1 + '/1'}.jpg`" :alt="hall.name">
                </div>
                <router-link
                    :id="`play-button-${index + 1}`"
                    class="button button-general"
                    :to="{path: ROUTES.GAME_VIEW.path}"
                >
                    {{ $t('login.playButton') }}
                </router-link>
            </div>
        </div>
    </div>
</template>

<script lang="ts" src="./halls-view-component.ts"/>

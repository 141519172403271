import { onMounted, onUnmounted, ref, SetupContext } from "vue";
import eventBus from "@/utils/event-bus";

export type MessageBoxType = "error" | "success" | "warning";
type CloseHandler = undefined | (() => void);

interface Props {
    title: string
    description: string
    type: MessageBoxType
}

export default {
    props: {
        title: {
            type: String,
            required: false
        },
        description: String,
        type: String as () => MessageBoxType
    },
    emits: ["onClose"],
    setup (props:Props, context: SetupContext) {
        const boxType = () => props.type; // undefined using default box style

        function handleClose () {
            context.emit("onClose");
        }
        return {
            boxType,
            handleClose
        };
    }
};

interface MessageBoxData {
    caption: string| undefined;
    message: string;
    type: MessageBoxType
    closeHandle: CloseHandler;
}

export function useMessageBox () {
    const isMessageBoxActive = ref(false);
    const message = ref("");
    const caption = ref();
    const messageBoxType = ref<MessageBoxType>("error");
    let closeHandler: CloseHandler;

    function showMessageBox (event: unknown) {
        if (event !== undefined) {
            const msg = event as MessageBoxData;
            caption.value = msg.caption;
            message.value = msg.message;
            isMessageBoxActive.value = true;
            messageBoxType.value = msg.type;
            closeHandler = msg.closeHandle;
        }
    }

    function messageBoxClose () {
        isMessageBoxActive.value = false;
        if (closeHandler) {
            closeHandler();
        }
        closeHandler = undefined;
    }

    onMounted(() => {
        eventBus.on("messageBox", showMessageBox);
    });
    onUnmounted(() => {
        eventBus.off("messageBox", showMessageBox);
    });

    return {
        caption,
        message,
        isMessageBoxActive,
        messageBoxType,
        messageBoxClose
    };
}

export function showMessageBox (type: MessageBoxType, localizedCaption: string | undefined, localizedMessage: string, closeHandle?: () => void) {
    eventBus.emit("messageBox", { message: localizedMessage, type, caption: localizedCaption, closeHandle });
}

import { AbstractMessageHandler } from "@/services/rest/messages/message-handler";
import { XmlRequest } from "@/services/rest/xml-request";
import { getChild, getRequiredAttribute, getRequiredChild, getValue } from "@/utils/xml-helper-functions";
import { LocalizedError } from "@/services/rest/errors";
import { ConfirmationMethod } from "@/services/rest/messages/system/a_cn_1_1_confirmation";

export class APo68WithdrawalMessageHandler extends AbstractMessageHandler<string> {
    protected readonly requestType: string = "A.PO.6.8";
    constructor (readonly accountNumber: string, readonly orderSum: number,
                 readonly currencyId: number, readonly confirmationMethod: ConfirmationMethod) {
        super();
    }

    buildRequest (request: XmlRequest) {
        const actionElement = request.addChild(request.body, "action", {
            type: this.requestType
        });
        const userElement = request.addChild(actionElement, "SessionUser");
        request.addChildWithValue(userElement, "AccountNumber", this.accountNumber);
        request.addChildWithValue(actionElement, "OrderedSum", this.orderSum.toString());
        request.addChildWithValue(actionElement, "CurrencyId", this.currencyId.toString());
        request.addChildWithValue(actionElement, "ConfirmationMethod", this.confirmationMethod.toString());
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    parseMessageBody (body: Element, _head: Element): string {
        const action = getRequiredChild(body, "action");
        const serverCode = getRequiredAttribute(action, "servercode");

        if (serverCode !== "4450") {
            throw new LocalizedError(`errors.mtl.code${serverCode}`);
        }
        const confirmationElement = getChild(action, "Confirmation");
        if (confirmationElement) {
            const codeElement = getChild(confirmationElement, "Code");
            if (codeElement) {
                return getValue(codeElement);
            }
        }
        return "";
    }
}

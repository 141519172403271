<template>
    <div class="modal site-popup active" id="partner-withdrawal-dialog">
        <div class="site-popup-container" :class="{'confirmed__message' : withdrawalOrder}">
            <div class="popup-close" @click.prevent="close"></div>
            <div class="title">{{$t("webdesktop.withdrawal.partner.caption")}}</div>
            <div class="content">
                <div class="site-popup-form">
                    <div class="part">
                        <div v-if="dialogState === 1"> <!-- State 1 Amount input -->
                            <div class="form-line">
                                <label>{{$t("webdesktop.deposit.amountInput.amount")}} {{currency}}</label>
                                <input type="number" v-model.number="amountRef" class="text-center" data-testid="amount-input">
                            </div>
                            <div class="site-popup-text" v-if="pointList" data-testid="point-list">
                                <ul>
                                    <li v-for="point of pointList" :key="point.id"><b>{{point.name}}:</b> {{point.cityName}}, {{point.address}}</li>
                                </ul>
                            </div>
                        </div>
                        <div v-if="withdrawalOrder" >
                            <div class="form-line mb-0">
                                <label>{{$t("webdesktop.withdrawalConfirm.title")}} {{withdrawalOrder.id}}</label>
                            </div>
                            <div class="form-line mb-0">
                                <label>{{$t("webdesktop.withdrawalConfirm.payCode")}}<b> {{formatVoucherNumber(withdrawalOrder.payCode)}}</b></label>
                            </div>
                            <div class="form-line mb-0">
                                <label>{{$t("webdesktop.withdrawalConfirm.total")}}
                                    <b>{{withdrawalOrder.orderSum}}{{withdrawalOrder.currencyMark}}</b></label>
                            </div>
                            <div class="form-line">
                                <label class="info-date">{{$t("webdesktop.withdrawalConfirm.expiration")}} {{$d(withdrawalOrder.expirationTime, 'long')}}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="site-popup-buttons">
                    <button class="button button-secondary" v-if="dialogState !== 4" @click.prevent="close">{{$t("common.forms.cancel")}}</button>
                    <button class="button" @click.prevent="submit" :class="{disabled: dialogState == 1 && (!amountRef || amountRef < 0)}" data-testid="withdrawal-submit">
                      {{$t(dialogState !== 4 ? "common.forms.continue" : "common.forms.ok")}}
                    </button>
                </div>
            </div>
        </div>
    </div>
    <confirm-code-dialog v-if="dialogState === 3" @success="onCodeSuccess" @close="onConfirmCodeClose" @onCodeError="onCodeError"/>
</template>

<script src="./partner-withdrawal-dialog-component.ts" />

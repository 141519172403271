export enum DocStatus {
    CONTENT_STATUS_FILE_LOADED="CONTENT_STATUS_FILE_LOADED",
    CONTENT_STATUS_BAD_QUALITY="CONTENT_STATUS_BAD_QUALITY",
    CONTENT_STATUS_TYPE_MISMATCH="CONTENT_STATUS_TYPE_MISMATCH",
    CONTENT_STATUS_INSUFFICIENT_DATA="CONTENT_STATUS_INSUFFICIENT_DATA",
    CONTENT_STATUS_ADD_PROOF_NEEDED="CONTENT_STATUS_ADD_PROOF_NEEDED",
    CONTENT_STATUS_PROFILE_DATA_MISMATCH="CONTENT_STATUS_PROFILE_DATA_MISMATCH",
    CONTENT_STATUS_PERSON_DATA_ACCEPTED="CONTENT_STATUS_PERSON_DATA_ACCEPTED",
    CONTENT_STATUS_DOCUMENT_NOT_ACCEPTED="CONTENT_STATUS_DOCUMENT_NOT_ACCEPTED"
}

export interface UserDocument {
    docType: string
    status: DocStatus
}

export interface UserContent {
    userContent: UserDocument[]
}
<template>
    <div class="select-wrapper">
        <div class="icon"><img :src="selectedCountryImage" alt="" v-if="countryComputed !== 0"></div>
        <Field id="country" name="country" required v-model="countryComputed" as="select">
            <option v-for="country in countryList" :key="country.id"
                    :selected="country.id === countryComputed" :value="country.id">
                {{country.name}}
            </option>
        </Field>
    </div>
</template>

<script src="./country-select-component.ts" />

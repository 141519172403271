<template>
    <div class="modal site-popup active profile-dialog" id="change-password-dialog" data-cy="change-password-dialog">
        <div class="site-popup-container modal-content">
            <div class="popup-close modal-close d-none" @click.prevent="onClose"></div>
            <div class="title">{{$t("webdesktop.changePasswordDialog.caption")}}</div>
            <div class="content">
                <Form @submit="changePassword" v-slot="{ errors, handleSubmit }">
                    <div class="site-popup-form">
                        <div class="part">
                            <div class="form-line password">
                                <label for="current-password" :class="{'label-error': errors.password}">{{$t("webdesktop.changePasswordDialog.currentPasswordLabel")}}</label>
                                <div class="position-relative">
                                    <div class="icons icon-eye">
                                        <img :src="require(`@assets/icon-eye-${oldPasswordVisible?'off':'on'}.png`)" alt="" @click="oldPasswordVisible = !oldPasswordVisible">
                                    </div>
                                    <Field id="current-password" name="currentPassword" :type="oldPasswordVisible?'text':'password'" autocomplete="current-password" rules="required" data-cy="current-password"/>
                                </div>
                                <div class="field-error" v-if="errors.currentPassword">{{errors.currentPassword}}</div>
                            </div>
                            <div class="form-line password">
                                <label for="password" :class="{'label-error': errors.password}">{{$t("webdesktop.changePasswordDialog.newPasswordLabel")}}</label>
                                <div class="position-relative">
                                    <div class="icons icon-eye">
                                        <img :src="require(`@assets/icon-eye-${newPasswordVisible?'off':'on'}.png`)" alt="" @click="newPasswordVisible = !newPasswordVisible">
                                    </div>
                                    <Field id="password" name="password" :type="newPasswordVisible?'text':'password'" autocomplete="new-password" rules="min:8|required" data-cy="password"/>
                                </div>
                                <div class="field-error" v-if="errors.password">{{errors.password}}</div>
                            </div>
                            <div class="form-line password-repeat">
                                <label for="confirm-password" :class="{'label-error': errors.confirmPassword}">{{$t("webdesktop.changePasswordDialog.newPasswordConfirmLabel")}}</label>
                                <div class="position-relative">
                                    <div class="icons icon-eye">
                                        <img :src="require(`@assets/icon-eye-${confirmPasswordVisible?'off':'on'}.png`)" alt="" @click="confirmPasswordVisible = !confirmPasswordVisible">
                                    </div>
                                    <Field id="confirm-password" name="confirmPassword" :type="confirmPasswordVisible?'text':'password'"  autocomplete="new-password" rules="min:8|confirmed:@password" data-cy="confirm-password"/>
                                </div>
                                <div class="field-error" v-if="errors.confirmPassword">{{errors.confirmPassword}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="site-popup-buttons">
                        <button class="button button-secondary" @click.prevent="onClose">{{$t("common.forms.cancel")}}</button>
                        <a id="submit-change-password" class="button button-general" @click.stop.prevent="handleSubmit(changePassword)" data-cy="submit-change-password">{{$t("webdesktop.changePasswordDialog.submitButton")}}</a>
                    </div>
                </Form>
            </div>
        </div>
        <div class="preloader" v-if="isRequestPending">
            <div class="preloader-el"></div>
        </div>
    </div>
</template>

<script src="./change-password-dialog-component.ts" />

import { EUserRole, EVerifyField, User, UserSession } from "@/model/user-accounts";
import { AbstractMessageHandler } from "@/services/rest/messages/message-handler";
import { XmlRequest } from "@/services/rest/xml-request";
import {
    getAttribute,
    getChild,
    getRequiredAttribute,
    getRequiredChild,
    getRequiredIntAttribute
} from "@/utils/xml-helper-functions";
import { toLocalTime } from "@/utils/time-utils";
import { LocalizedError } from "@/services/rest/errors";

export class QUs111UserInfo extends AbstractMessageHandler<User> {
    protected readonly requestType: string = "Q.US.1.11";

    constructor () {
        super();
    }

    buildRequest (request: XmlRequest) {
        const actionElement = request.addChild(request.body, "query", {
            type: this.requestType
        });
        const selectorElement = request.addChild(actionElement, "Selector");
        request.addChild(selectorElement, "SelectObject", {
            class: "ps.user.User",
            allFields: "true"
        });
        request.addChild(selectorElement, "SelectObject", {
            class: "ps.user.UserAccount",
            allFields: "true"
        });
        request.addChild(selectorElement, "SelectObject", {
            class: "ps.user.UserRole",
            allFields: "true"
        });
        request.addChild(selectorElement, "SelectObject", {
            class: "ps.user.UserVerifyField",
            allFields: "true"
        });
        request.addChild(selectorElement, "SelectObject", {
            class: "ps.user.PaySystem",
            allFields: "true"
        });
        request.addChild(selectorElement, "SelectObject", {
            class: "ps.user.UserParams",
            allFields: "true"
        });
    }
    // eslint-disable-next-line
    parseMessageBody (body: Element, _head: Element): User {
        const action = getRequiredChild(body, "query");
        const serverCode = getRequiredAttribute(action, "servercode");
        if (serverCode === "1190") {
            const userElement = getRequiredChild(action, "US");
            const userSessions: UserSession[] = [];
            const userSessionsElement = getChild(userElement, "UserSessions");
            if (userSessionsElement) {
                for (let sessionElement = userSessionsElement.firstElementChild; sessionElement != null; sessionElement = sessionElement.nextElementSibling) {
                    userSessions.push({
                        ipAddress: getRequiredAttribute(sessionElement, "ipAddress"),
                        registerTime: toLocalTime(new Date(getRequiredAttribute(sessionElement, "registerTime"))),
                        id: getRequiredIntAttribute(sessionElement, "sessionId")
                    });
                }
            }
            const birthday = getRequiredAttribute(userElement, "birthday");
            const roles: EUserRole[] = [];
            const rolesElement = getChild(userElement, "UserRoleList");
            if (rolesElement !== undefined) {
                for (let roleElement: Element | null = rolesElement.firstElementChild; roleElement !== null; roleElement = roleElement?.nextElementSibling) {
                    roles.push(getRequiredIntAttribute(roleElement, "Id") as EUserRole);
                }
            }
            const verifiedFields = new Set<EVerifyField>();
            const userVerifyElement = getChild(userElement, "UserVerifyFieldList");
            if (userVerifyElement) {
                for (let uf = userVerifyElement.firstElementChild; uf != null; uf = uf.nextElementSibling) {
                    if (getRequiredAttribute(uf, "isVerify") === "true") {
                        const key = getRequiredAttribute(uf, "fieldName") as keyof typeof EVerifyField;
                        verifiedFields.add(EVerifyField[key]);
                    }
                }
            }
            return {
                userId: getRequiredIntAttribute(userElement, "Id"),
                userNumber: getRequiredAttribute(userElement, "UserNumber"),
                firstName: getRequiredAttribute(userElement, "firstname"),
                lastName: getRequiredAttribute(userElement, "lastname"),
                middleName: getRequiredAttribute(userElement, "middlename"),
                address: getRequiredAttribute(userElement, "address"),
                phone: getRequiredAttribute(userElement, "phone"),
                email: getRequiredAttribute(userElement, "email"),
                login: getRequiredAttribute(userElement, "login"),
                partnerId: getRequiredIntAttribute(userElement, "partnerId"),
                IdentificationCode: getAttribute(userElement, "identificationCode"),
                birthday: birthday !== "0000-00-00" ? birthday : "",
                registrationTime: toLocalTime(new Date(getRequiredAttribute(userElement, "registrationTime"))),
                countryId: getRequiredIntAttribute(userElement, "countryId"),
                city: getAttribute(userElement, "cityText"),
                sessions: userSessions,
                userRoles: roles
            };
        }
        if (serverCode === "1191") {
            throw new Error("Session expired");
        }
        throw new LocalizedError(`errors.mtl.qUs111.code${serverCode}`);
    }
}

<template>
    <div id="reset-password-dialog" class="modal site-popup active ">
        <div class="site-popup-container">
            <div class="popup-close" @click.prevent="onClose"></div>
            <div class="title">{{$t("resetPasswordDialog.caption")}}</div>
            <div class="site-popup-tabs">
                <!-- Content Restore Via Mobile -->
                <div class="content active">
                    <div class="part">
                        <div class="form-line phone-line">
                            <div class="line-wrap">
                                <div class="select-wrapper">
                                    <div class="icon"><img src="/flags/ua.png" alt=""></div>
                                </div>
                                <div class="mobile-wrap">
                                    <input class="num" type="text" :value="phoneCode" readonly>
                                    <input id="phone-input" type="text" minlength="9" :placeholder="$t('resetPasswordDialog.phonePlaceholder')" v-model="data">
                                </div>
                            </div>
                        </div>
                        <div class="form-line">
                            <div class="text">
                                {{$t("resetPasswordDialog.phoneTabText2")}}
                            </div>
                        </div>
                    </div>
                    <div class="site-popup-buttons mob-col">
                        <button id="submit-phone-button" type="submit" class="button" :class="{disabled: !data}" @click.prevent="resetPassword">{{$t("resetPasswordDialog.submitButton")}}</button>
                        <button id="close-button" class="button button-secondary" type="button" @click="onClose">{{$t("common.forms.close")}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <confirm-code-dialog v-if="confirmDialogVisible" @close="onConfirmDialogClose" @success="onConfirmSuccess" @onCodeError="onCodeError"/>
</template>

<script src="./reset-password-dialog-component.ts" />

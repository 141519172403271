<template>
    <iframe v-if="currentGameUrl" allow="fullscreen *; picture-in-picture *; screen-wake-lock *; speaker *; sync-xhr *; web-share *; vibrate *" :src="currentGameUrl" data-cy="iframe-current-game"/>
    <div class="preloader virtual" v-else>
        <div class="preloader-el"></div>
    </div>
</template>

<script src="./game-view-component.ts">

</script>

import { IRestService } from "@/services/rest/rest-service";
import { use } from "@/utils/dependency-injection";
import { ISettingsService } from "@/services/settings/settings-service";

export const services = {
    REST: Symbol("Rest service"),
    SETTINGS: Symbol("Settings service")
};

export function useRestService (): IRestService {
    return use(services.REST);
}

export function useSettingsService (): ISettingsService {
    return use(services.SETTINGS);
}

export enum PaymentSystems {
    WITHDRAWAL_METHOD_VOUCHER_INSTANT = "WITHDRAWAL_METHOD_VOUCHER_INSTANT",
    DEPOSIT_IPAY = "DEPOSIT_IPAY",
}

export interface PaymentSystemData {
    paymentSystemType: PaymentSystems,
    availableCurrencies: number[]
}

export function isPaymentSystemPresent (paymentSystemList: PaymentSystemData[], paymentSystem: PaymentSystems): boolean {
    return paymentSystemList.find(s => s.paymentSystemType === paymentSystem) !== undefined;
}
import { defineStore } from "pinia";
import { UserBalance } from "@/model/user-balance";

export const useBalanceStore = defineStore("balanceStore", {
    state: () => ({
        balance: undefined as UserBalance | undefined
    }),
    getters: {
        balanceTotal: (state) => {
            if (state.balance === undefined) {
                return undefined;
            }
            return (state.balance.mainBalance?.primaryAccount?.sumAccount ?? 0) +
                (state.balance.casinoBalance?.primaryAccount?.sumAccount ?? 0);
        }
    }
});

import { AbstractMessageHandler } from "@/services/rest/messages/message-handler";
import { XmlRequest } from "@/services/rest/xml-request";
import { getRequiredAttribute, getRequiredChild } from "@/utils/xml-helper-functions";
import { LocalizedError } from "@/services/rest/errors";

export class ACs12CloseGameFrameRequestHandler extends AbstractMessageHandler<void> {
    protected readonly requestType: string = "A.CS.1.2";

    constructor (private gameId: string) {
        super();
    }

    buildRequest (request: XmlRequest): void {
        const actionElement = request.addChild(request.body, "action", {
            type: this.requestType
        });
        request.addChildWithValue(actionElement, "CasinoGameMark", this.gameId);
    }

    protected parseMessageBody (body: Element) {
        const action = getRequiredChild(body, "action");
        const serverCode = getRequiredAttribute(action, "servercode");
        if (serverCode !== "8120") {
            throw new LocalizedError(`errors.mtl.aCs12.code${serverCode}`);
        }
    }
}

import { AbstractMessageHandler } from "@/services/rest/messages/message-handler";
import { EWithdrawalStatus, EWithdrawalType, WithdrawalOrderEx } from "@/model/withdrawal";
import { XmlRequest } from "@/services/rest/xml-request";
import { dateToMtlStr, parseDate } from "@/utils/time-utils";
import {
    getRequiredAttribute,
    getRequiredChild,
    getRequiredFloatAttribute,
    getRequiredIntAttribute
} from "@/utils/xml-helper-functions";
import { LocalizedError, NotAuthorizedError } from "@/services/rest/errors";

export class QPo61WithdrawalOrders extends AbstractMessageHandler<WithdrawalOrderEx[]> {
    constructor (readonly startDate: Date, readonly endDate: Date) {
        super();
    }

    protected readonly requestType: string = "Q.PO.6.1";

    buildRequest (request: XmlRequest) {
        const actionElement = request.addChild(request.body, "query", {
            type: this.requestType
        });
        const filterElement = request.addChild(actionElement, "Filter", {
            logic: "AND"
        });
        const sessionFilterElement = request.addChild(filterElement, "SessionFilter");
        request.addChildWithValue(sessionFilterElement, "User", "true");
        const orderFilterElement = request.addChild(filterElement, "OrderWithdrawalFilter");
        const orderPeriodElement = request.addChildWithValue(orderFilterElement, "PeriodCondition");
        request.addChildWithValue(orderPeriodElement, "StartTime", dateToMtlStr(this.startDate));
        request.addChildWithValue(orderPeriodElement, "FinishTime", dateToMtlStr(this.endDate));

        const selectorElement = request.addChild(actionElement, "Selector");
        request.addChild(selectorElement, "SelectObject", {
            allFields: "true",
            class: "ps.payoperation.OrderWithdrawal"
        });
        request.addChild(selectorElement, "SelectObject", {
            allFields: "true",
            class: "ps.point.Point"
        });
        request.addChild(selectorElement, "SelectObject", {
            allFields: "true",
            class: "ps.point.Station"
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    parseMessageBody (body: Element, head: Element): WithdrawalOrderEx[] {
        const action = getRequiredChild(body, "query");
        const serverCode = getRequiredAttribute(action, "servercode");
        if (serverCode === "1001" || serverCode === "1313") {
            throw new NotAuthorizedError();
        }
        if (serverCode === "4500") {
            const listElement = getRequiredChild(action, "OrderWithdrawalList");
            const result: WithdrawalOrderEx[] = [];
            for (let orderElement = listElement.firstElementChild; orderElement != null; orderElement = orderElement.nextElementSibling) {
                result.push({
                    id: getRequiredIntAttribute(orderElement, "Id"),
                    orderTime: parseDate(getRequiredAttribute(orderElement, "orderTime")),
                    changeStatusTime: parseDate(getRequiredAttribute(orderElement, "changeStatusTime")),
                    currencyId: getRequiredIntAttribute(orderElement, "currencyId"),
                    currencyMark: getRequiredAttribute(orderElement, "currencyMark"),
                    payCode: getRequiredAttribute(orderElement, "payCode"),
                    expirationTime: parseDate(getRequiredAttribute(orderElement, "expirationTime")),
                    sumOrder: getRequiredFloatAttribute(orderElement, "sumOrder"),
                    withdrawalType: getRequiredAttribute(orderElement, "withdrawalType") as EWithdrawalType,
                    status: getRequiredAttribute(orderElement, "status") as EWithdrawalStatus,
                    GUID: getRequiredAttribute(orderElement, "GUID")
                });
            }
            return result;
        }
        throw new LocalizedError(`errors.mtl.code${serverCode}`);
    }
}

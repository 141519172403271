function getTypedAttribute<T> (element: Element | undefined, attributeName: string, transform: (value: string) => T, defaultValue?: T): T | undefined {
    if (element) {
        const value = element.getAttribute(attributeName);
        if (value != null) {
            try {
                return transform(value.trim());
            } catch (ex) {
                return defaultValue;
            }
        }
    }
    return defaultValue;
}

export function getAttribute (element: Element, attributeName: string): string {
    const attribute = element.getAttribute(attributeName);
    return attribute ? attribute.trim() : "";
}

export function getRequiredAttribute (element: Element, attributeName: string, debugInfo?: unknown): string {
    const attribute = element.getAttribute(attributeName);
    return attribute?.trim() ?? requiredAttributeError(element, attributeName, debugInfo);
}

function requiredAttributeError<T> (element: Element, attributeName: string, debugInfo?: unknown): T {
    throw new Error(`Attribute ${attributeName} is required for node ${element.tagName}${debugInfo ? ":" + debugInfo : ""}`);
}

export function getIntAttribute (element: Element | undefined, attributeName: string, defaultValue?: number): number | undefined {
    return getTypedAttribute(element, attributeName, value => parseInt(value), defaultValue);
}

export function getRequiredIntAttribute (element: Element, attributeName: string): number {
    const result = getIntAttribute(element, attributeName);
    return result ?? requiredAttributeError(element, attributeName);
}

export function getBoolAttribute (element: Element, attributeName: string, defaultValue?: boolean): boolean | undefined {
    return getTypedAttribute(element, attributeName, value => value.toLowerCase() === "true", defaultValue);
}

export function getRequiredBoolAttribute (element: Element, attributeName: string): boolean {
    const result = getBoolAttribute(element, attributeName);
    return result ?? requiredAttributeError(element, attributeName);
}

export function getFloatAttribute (element: Element, attributeName: string, defaultValue?: number): number | undefined {
    return getTypedAttribute(element, attributeName, value => parseFloat(value), defaultValue);
}

export function getRequiredFloatAttribute (element: Element, attributeName: string): number {
    const result = getFloatAttribute(element, attributeName);
    return result ?? requiredAttributeError(element, attributeName);
}

export function getFirstLevelChildrenByTagName (parent: Element, name: string): Element[] {
    const nodeList: Element[] = [];
    for (let child = parent.firstChild; child != null; child = child.nextSibling) {
        if (child.nodeType === Node.ELEMENT_NODE && name === child.nodeName) {
            nodeList.push(child as Element);
        }
    }
    return nodeList;
}

export function single<T> (root: Element, childTagsName: string, transform: (element: Element) => T): T | undefined {
    const list = getFirstLevelChildrenByTagName(root, childTagsName);
    if (list.length > 0) {
        return transform(list[0]);
    }
    return undefined;
}

export function forEach (root: Element, childTagsName: string, consumer: (el: Element) => void) {
    const list = getFirstLevelChildrenByTagName(root, childTagsName);
    list.forEach(consumer);
}

export function getValue (el: Element | undefined): string {
    return el?.firstChild?.nodeValue ?? "";
}

export function getRequiredValue (el: Element): string {
    const value = getValue(el);
    if (value) {
        return value;
    } else {
        throw new Error(`element [${el.tagName}] has no value`);
    }
}

export function getRequiredIntValue (el: Element): number {
    const value = getValue(el);
    if (value) {
        return parseInt(value);
    } else {
        throw new Error(`element [${el.tagName}] has no value`);
    }
}

export function getIntValue (el: Element| undefined, defaultValue: number): number {
    const value = getValue(el);
    return value ? parseInt(value) : defaultValue;
}

export function getRequiredFloatValue (el: Element): number {
    const value = getValue(el);
    if (value) {
        return parseFloat(value);
    } else {
        throw new Error(`element [${el.tagName}] has no value`);
    }
}

export function getRequiredBooleanValue (el: Element): boolean {
    const value = getValue(el);
    if (value) {
        return parseInt(value) === 1;
    } else {
        throw new Error(`element [${el.tagName}] has no value`);
    }
}

export function getRequiredChild (el: Element, childTag: string): Element {
    const children = el.getElementsByTagName(childTag);
    if (children.length > 0) {
        return children[0];
    } else {
        throw new Error(`Tag [${childTag}] not found`);
    }
}

export function getChild (el: Element, childTag: string): Element | undefined {
    const children = el.getElementsByTagName(childTag);
    if (children.length > 0) {
        return children[0];
    } else {
        return undefined;
    }
}

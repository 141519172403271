import { CLOSE_EVENT } from "@/utils/events";
import { Field, Form } from "vee-validate";
import { ref, SetupContext } from "vue";
import { showMessageBox } from "@/components/messagebox/message-box-component";
import { useI18n } from "vue-i18n";
import { useRestService } from "@/services";

interface FormData {
    currentPassword: string;
    password: string;
    confirmPassword: string;
}

export default {
    name: "ChangePasswordDialog",
    emits: [CLOSE_EVENT],
    components: {
        Form, Field
    },
    setup (props: unknown, context: SetupContext) {
        const restService = useRestService();
        const i18n = useI18n();
        const oldPasswordVisible = ref(false);
        const newPasswordVisible = ref(false);
        const confirmPasswordVisible = ref(false);
        const isRequestPending = ref(false);

        function onClose () {
            context.emit(CLOSE_EVENT);
        }

        function changePassword (data: FormData) {
            isRequestPending.value = true;
            restService.changePassword(data.currentPassword, data.password).then(() => {
                isRequestPending.value = false;
                onClose();
                showMessageBox("success", undefined, i18n.t("webdesktop.changePasswordDialog.successMessage"));
            }).catch(() => {
                isRequestPending.value = false;
            });
        }
        return {
            oldPasswordVisible,
            newPasswordVisible,
            confirmPasswordVisible,
            isRequestPending,
            onClose,
            changePassword
        };
    }
};

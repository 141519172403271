<template>
    <Form @submit="onSubmit" v-if="true" v-slot="{ errors }">
        <div class="slots-auth">
            <div class="slots-auth-logo">
                <img class="logo" src="/4444logo.png" alt="4444">
            </div>
            <div class="slots-auth-container">
                <div class="slots-auth-content">
                    <div v-show="!showForm" class="slots-auth-content-info desktop">
                        <h2 class="title">
                            {{$t("brand.name")}}
                        </h2>
                        <p class="description">
                            {{$t("brand.description1")}}<br/>
                            <br/>
                            {{$t("brand.description2")}}
                        </p>
                        <button id="play-button" class="button btn-play" @click="play()" data-testid="play-button">{{$t("login.playButton")}}</button>
                        <button class="button btn-more" type="button" @click="showModal = true">{{$t("login.detailsButton")}}</button>
                    </div>
                    <div v-show="showForm" class="slots-auth-content-form desktop">
                        <h3 class="title">
                            {{$t("login.title")}}
                        </h3>
                        <div class="slots-auth-tabs">
                            <div class="tab-item" v-for="(tab, index) in [`${$t('tabs.phone')}`, `${$t('tabs.number')}`]" :key="index" :class="{active: tabActive === index}" @click="tabActive = index">
                                {{ tab }}
                            </div>
                        </div>
                        <div class="form-fields">
                            <div class="form-line" v-if="tabActive === 0" :class="{'label-error': errors.phone || customErrors.phone}">
                                <label for="phone">
                                    {{$t("login.login")}}
                                </label>
                                <div class="line-wrap">
                                    <div class="select-wrapper">
                                        <div class="icon"><img src="/flags/ua.png" alt=""></div>
                                    </div>
                                    <div class="mobile-wrap">
                                        <input class="num" type="text" :value="phoneCode" readonly>
                                        <Field type="tel" id="phone" name="phone" rules="required" @keydown="onPhoneKeyDown"/>
                                        <div class="field-error" v-if="errors.phone || customErrors.phone">{{errors.phone}}{{customErrors.phone}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-line" v-else :class="{'label-error': errors.phone || customErrors.phone}">
                                <label for="phone">
                                    {{$t("login.loginNumber")}}
                                </label>
                                <div class="line-wrap">
                                    <Field type="text" id="account-number" name="accountNumber" rules="required" @keydown="onPhoneKeyDown"/>
                                    <div class="field-error" v-if="errors.phone || customErrors.phone">{{errors.phone}}{{customErrors.phone}}</div>
                                </div>
                            </div>
                            <div class="form-line password" :class="{'label-error': errors.password }">
                                <label for="password">
                                    {{$t("login.password")}}
                                </label>
                                <div class="line-wrap">
                                    <password-field :isConfirm="false" :errors="errors" ></password-field>
                                </div>
                            </div>
                            <div class="under-fields">
                                <div class="forgot-password">
                                    <a href="#" @click.prevent="isResetPasswordDialogVisible = true">{{$t("login.forgotPassword")}}</a>
                                </div>
                                <button id="login-button" class="button log-in" type="submit">{{$t("login.submitButton")}}</button>
                                <!-- <button class="button registration" @click="$router.push({ name: ROUTES.REGISTRATION.name })">{{$t("login.registrationButton")}}</button> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Form>
    <modal-description v-model:show="showModal" :title="$t('login.detailsTitle')">
        <span v-html="$t('login.details')"></span>
    </modal-description>
    <ResetPasswordDialog v-if="isResetPasswordDialogVisible" @close="isResetPasswordDialogVisible = false"></ResetPasswordDialog>
</template>

<script lang="ts" src="./login-view-component.ts"/>

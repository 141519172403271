import { ref, SetupContext, computed } from "vue";
import ConfirmCodeDialog from "@/components/confirm-code-dialog/ConfirmCodeDialog.vue";
import { CLOSE_EVENT } from "@/utils/events";
import { showMessageBox } from "@/components/messagebox/message-box-component";
import { useI18n } from "vue-i18n";
import { useRestService } from "@/services";
import { countries } from "@/model/consts/countries";

export default {
    name: "ResetPasswordDialog",
    emits: [CLOSE_EVENT],
    components: {
        ConfirmCodeDialog
    },
    setup (props: unknown, context: SetupContext) {
        const data = ref();
        const restService = useRestService();
        const confirmDialogVisible = ref(false);
        const i18n = useI18n();
        const countryId = ref<number>(217);
        const phoneCode = computed(() => {
            return countryId.value ? countries.getById(countryId.value)?.phoneCode : "";
        });

        function onClose () {
            context.emit(CLOSE_EVENT);
        }

        function resetPassword () {
            if (data.value) {
                restService.resetPassword(phoneCode.value + data.value).then(() => {
                    confirmDialogVisible.value = true;
                });
            }
        }

        function onConfirmDialogClose () {
            confirmDialogVisible.value = false;
        }

        function onConfirmSuccess () {
            showMessageBox("success", undefined, i18n.t("resetPasswordDialog.success"));
            onClose();
        }

        function onCodeError (message: string) {
            showMessageBox("error", undefined, i18n.t(message));
        }
        return {
            data,
            confirmDialogVisible,
            phoneCode,
            onClose,
            resetPassword,
            onConfirmSuccess,
            onCodeError,
            onConfirmDialogClose
        };
    }
};

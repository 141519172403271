import { createI18n, Path } from "vue-i18n";
import generalMessagesUa from "@/i18n/messages/general-messages-ua";
import { I18nSetup } from "@/i18n/i18n-settings";

export interface Localization {
    t (key: Path): string;

    t (key: Path, named: unknown): string;
}

export const i18n = createI18n({
    legacy: false,
    globalInjection: true,
    warnHtmlInMessage: false,
    locale: "ua",
    messages: {
        ua: { ...generalMessagesUa }
    },
    datetimeFormats: I18nSetup.dateTimeFormats,
    numberFormats: I18nSetup.numberFormats
});

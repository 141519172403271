<script setup lang="ts">
import { onMounted } from "vue";
import { useRouter } from "vue-router";
import { ROUTES } from "@/router/route-consts";

onMounted(() => {
    const router = useRouter();
    if (window === parent) {
        router.push({ path: ROUTES.LOGIN.path }).then();
    } else {
        parent.postMessage("close-session");
    }
});
</script>

<template>
    <div>Close session</div>
</template>

<style scoped>

</style>

import FinancesTabs from "@/views/finances/tabs/FinancesTabs.vue";
import { computed, onMounted, ref, watch } from "vue";
import { useRestService } from "@/services";
import { Operation } from "@/model/operation";
export default {
    name: "DepositOrder",
    components: { FinancesTabs },
    setup () {
        const restService = useRestService();
        const operationsRef = ref<Operation[]>([]);
        const itemsPerPageRef = ref<number>(10);
        const currentPage = ref<number>(1);
        const totalPages = computed(() => Math.ceil(operationsRef.value.length / itemsPerPageRef.value));
        const requestPending = ref(false);
        const startDate = new Date();
        startDate.setMonth(startDate.getMonth() - 1);
        const startDateRef = ref(startDate.toISOString().split("T")[0]);
        const endDateRef = ref(new Date().toISOString().split("T")[0]);
        const operationsPaged = computed(() => {
            const offset = (currentPage.value - 1) * itemsPerPageRef.value;
            return operationsRef.value.slice(offset).slice(0, itemsPerPageRef.value);
        });

        onMounted(() => {
            dateChanged();
        });

        function dateChanged () {
            const startDate = new Date(startDateRef.value);
            const endDate = new Date(endDateRef.value);
            endDate.setTime(endDate.getTime() + (23 * 60 * 60 + 59 * 60 + 59) * 1000);
            requestPending.value = true;
            const operations = ["PAY_OPERATION_REFERRAL_ACCOUNT_DEPOSIT", "PAY_OPERATION_DEPOSIT_DIRECT_REFILL_ACCOUNT", "PAY_OPERATION_INCREASE_ACCOUNT"];
            restService.getOperations(operations, startDate, endDate)
                .then((c) => {
                    operationsRef.value = c;
                    requestPending.value = false;
                });
        }
        watch(itemsPerPageRef, () => {
            currentPage.value = 1;
        });

        return {
            operationsPaged,
            itemsPerPageRef,
            operationsRef,
            currentPage,
            totalPages,
            requestPending,
            startDateRef,
            endDateRef,
            dateChanged
        };
    }
};

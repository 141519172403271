import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import MainView from "@/views/main-view/MainView.vue";
import { ROUTES } from "@/router/route-consts";
import LoginView from "@/views/login-view/LoginView.vue";
import RegistrationView from "@/views/registration-view/RegistrationView.vue";
import GameView from "@/views/game-view/GameView.vue";
import DepositView from "@/views/deposit-view/DepositView.vue";
import { useUserStore } from "@/store/user-store";
import WithdrawalView from "@/views/withdrawal-view/WithdrawalView.vue";
import ProfileView from "@/views/profile-view/ProfileView.vue";
import ConfirmView from "@/views/confirm-view/ConfirmView.vue";
import DepositOrders from "@/views/finances/deposit-orders/DepositOrders.vue";
import WithdrawalOrders from "@/views/finances/withdrawal-orders/WithdrawalOrders.vue";
import Cookies from "js-cookie";
import { SESSION_COOKIE } from "@/consts/cookies-consts";
import { isMobile } from "@/utils/browser";
import CloseSession from "@/components/CloseSession.vue";
import HallsView from "@/views/halls/HallsView.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        component: MainView,
        children: [
            {
                path: ROUTES.GAME_VIEW.path,
                name: ROUTES.GAME_VIEW.name,
                component: GameView,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: ROUTES.DEPOSIT_VIEW.path,
                name: ROUTES.DEPOSIT_VIEW.name,
                component: DepositView,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: ROUTES.WITHDRAWAL_VIEW.path,
                name: ROUTES.WITHDRAWAL_VIEW.name,
                component: WithdrawalView,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: ROUTES.PROFILE.path,
                name: ROUTES.PROFILE.name,
                component: ProfileView,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: ROUTES.DEPOSIT_ORDERS.path,
                name: ROUTES.DEPOSIT_ORDERS.name,
                component: DepositOrders,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: ROUTES.WITHDRAWAL_ORDERS.path,
                name: ROUTES.WITHDRAWAL_ORDERS.name,
                component: WithdrawalOrders,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: ROUTES.HALLS.path,
                name: ROUTES.HALLS.name,
                component: HallsView,
                meta: {
                    requiresAuth: true
                }
            }
        ]
    },
    {
        path: ROUTES.LOGIN.path,
        name: ROUTES.LOGIN.name,
        component: LoginView,
        props: {
            showForm: true
        },
        meta: {
            requiresAuth: false
        }
    },
    {
        path: ROUTES.CODECONFIRM.path,
        name: ROUTES.CODECONFIRM.name,
        component: ConfirmView,
        meta: {
            requiresAuth: false
        }
    },
    {
        path: ROUTES.HOME.path,
        name: ROUTES.HOME.name,
        component: LoginView,
        props: {
            showForm: false
        },
        meta: {
            requiresAuth: false
        }
    },
    {
        path: ROUTES.REGISTRATION.path,
        name: ROUTES.REGISTRATION.name,
        component: RegistrationView
    },
    {
        path: ROUTES.CLOSE_SESSION.path,
        name: ROUTES.CLOSE_SESSION.name,
        component: CloseSession
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach(async (to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        const userStore = useUserStore();
        const storedSession = Cookies.get(SESSION_COOKIE);
        if (!userStore.isLoggedIn && !storedSession) {
            next({ name: ROUTES.LOGIN.name });
        } else {
            next();
        }
    } else {
        if (to.hash.startsWith("#confirmation")) {
            const code = new URLSearchParams(to.hash.substring(to.hash.indexOf("?"))).get("code");
            next({ path: ROUTES.CODECONFIRM.path + "?code=" + code });
            return;
        }
        if (to.path === "/") {
            if (!isMobile()) {
                next({ name: ROUTES.LOGIN.name });
            } else {
                next({ name: ROUTES.HOME.name });
            }
        }
        next();
    }
});

export default router;

import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "halls" }
const _hoisted_2 = { class: "halls-items" }
const _hoisted_3 = { class: "img" }
const _hoisted_4 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.halls, (hall, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: _normalizeClass(["item", { disabled: hall.disabled }])
        }, [
          _createElementVNode("h2", null, "«" + _toDisplayString(hall.name) + "»", 1),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("img", {
              src: `/halls/${index + 1 + '/1'}.jpg`,
              alt: hall.name
            }, null, 8, _hoisted_4)
          ]),
          _createVNode(_component_router_link, {
            id: `play-button-${index + 1}`,
            class: "button button-general",
            to: {path: _ctx.ROUTES.GAME_VIEW.path}
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('login.playButton')), 1)
            ]),
            _: 2
          }, 1032, ["id", "to"])
        ], 2))
      }), 128))
    ])
  ]))
}
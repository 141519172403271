import { defineStore } from "pinia";
import type { ProfileUpdateData, User } from "@/model/user-accounts";

export const useUserStore = defineStore("userStore", {
    state: () => ({
        user: undefined as User | undefined
    }),
    getters: {
        isLoggedIn: state => state.user !== undefined
    },
    actions: {
        updateProfile (data: ProfileUpdateData) {
            if (this.user) {
                if (data.lastName) {
                    this.user.lastName = data.lastName;
                }
                if (data.firstName) {
                    this.user.firstName = data.firstName;
                }
                if (data.birthday) {
                    this.user.birthday = data.birthday.toString();
                }
                if (data.middleName) {
                    this.user.middleName = data.middleName;
                }
                if (data.login) {
                    this.user.login = data.login;
                }
            }
        }
    }
});

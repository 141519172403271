export default {
    name: "ModalDescription",
    props: {
        title: String,
        show: {
            type: Boolean,
            default: false
        }
    }
};

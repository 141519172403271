import { IntlDateTimeFormats, IntlNumberFormats } from "vue-i18n";

export namespace I18nSetup {
    export const dateTimeFormats: IntlDateTimeFormats = {
        en: {
            short: {
                year: "numeric",
                month: "numeric",
                day: "numeric"
            },
            long: {
                year: "numeric",
                month: "numeric",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
                second: "numeric",
                hour12: false
            },
            time: {
                hour: "numeric",
                minute: "numeric",
                second: "numeric"
            }
        },
        es: {
            short: {
                year: "numeric",
                month: "numeric",
                day: "numeric"
            },
            long: {
                year: "numeric",
                month: "numeric",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
                second: "numeric",
                hour12: false
            },
            time: {
                hour: "numeric",
                minute: "numeric",
                second: "numeric"
            }
        },
        ru: {
            short: {
                day: "numeric",
                month: "numeric",
                year: "numeric"
            },
            long: {
                day: "numeric",
                month: "numeric",
                year: "numeric",
                hour: "numeric",
                minute: "numeric",
                second: "numeric",
                hour12: false
            },
            time: {
                hour: "numeric",
                minute: "numeric",
                second: "numeric"
            }
        },
        ua: {
            short: {
                day: "numeric",
                month: "numeric",
                year: "numeric"
            },
            long: {
                day: "numeric",
                month: "numeric",
                year: "numeric",
                hour: "numeric",
                minute: "numeric",
                second: "numeric",
                hour12: false
            },
            time: {
                hour: "numeric",
                minute: "numeric",
                second: "numeric"
            }
        }
    };

    export const numberFormats: IntlNumberFormats = {
        en: {
            currency: {
                style: "decimal",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            }
        },
        es: {
            currency: {
                style: "decimal",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            }
        },
        pt: {
            currency: {
                style: "decimal",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            }
        },
        ru: {
            currency: {
                style: "decimal",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            }
        }
    };
}

import Axios, { AxiosRequestConfig } from "axios";
import { LocalizedError, NotAuthorizedError } from "@/services/rest/errors";
import { XmlRequest } from "@/services/rest/xml-request";
import { ClockProvider } from "@/utils/time-utils";
import { appLogger } from "@/utils/logger";
import { MessageHandler } from "@/services/rest/messages/message-handler";
import { AGe22ApplicationStartMessageHandler } from "@/services/rest/messages/system/a_ge_2_2_application_start";
import { AUs23RegistrationMessageHandler } from "@/services/rest/messages/user/a_us_2_3_registration";
import { AUs13Authorization } from "@/services/rest/messages/user/a_us_1_3_authorization";
import { ProfileUpdateData, User } from "@/model/user-accounts";
import { AuthorizationData } from "@/model/authorization-data";
import { ACs29CasinoEnterMessageHandler } from "@/services/rest/messages/game/a_cs_2_9_casino-enter";
import {
    ACs11GetGameFrameRequestHandler,
    StartGameFrameResponse
} from "@/services/rest/messages/game/a_cs_1_1-get-game-frame-request";
import { ACs12CloseGameFrameRequestHandler } from "@/services/rest/messages/game/a_cs_1_2-close-game-frame-request";
import { UserBalance } from "@/model/user-balance";
import { QUs112ExtendedBalance } from "@/services/rest/messages/user/q_us_1_12_extended-get-balance";
import { VoucherSellInfo } from "@/views/deposit-view/voucher-deposit-dialog/voucher";
import { QVc11FindVoucherMessageHandler } from "@/services/rest/messages/account/q_vc_1_1_find-voucher";
import { AVc21DepositMessageHandler } from "@/services/rest/messages/account/a_vc_2_1_voucher-deposit";
import { QGe23PointListMessageHandler } from "@/services/rest/messages/system/q_ge_2_3_point-list";
import { Point } from "@/model/point";
import { ISettingsService } from "@/services/settings/settings-service";
import {
    ACn11EmailConfirmation,
    ConfirmationMethod,
    ConfirmationResult
} from "@/services/rest/messages/system/a_cn_1_1_confirmation";
import { APo68WithdrawalMessageHandler } from "@/services/rest/messages/account/a_po_6_8_withdrawal";
import { ACs210CasinoLeaveMessageHandler } from "@/services/rest/messages/game/a_cs_2_10_casino-leave";
import { AUs15ResetPassword } from "@/services/rest/messages/user/a_us_1_5_reset_password";
import { AUs14UpdateProfileMessageHandler } from "@/services/rest/messages/user/a_us_1_4_update-profile";
import { AUs16ChangePassword } from "@/services/rest/messages/user/a_us_1_6_change-password";
import { ACt11SendFileMessageHandler } from "@/services/rest/messages/user/a_ct_1_1_send-file";
import { QUs111UserContent } from "@/services/rest/messages/user/q_us_1_11_user-content";
import { UserContent } from "@/model/user-content";
import { WithdrawalOrderEx } from "@/model/withdrawal";
import { QPo61WithdrawalOrders } from "@/services/rest/messages/account/q_po_6_1_get-withdrawal-orders";
import { Operation } from "@/model/operation";
import { QPo11OperationsByDate } from "@/services/rest/messages/account/q_po_1_1_get-operations";
import { APo62CancelWithdrawalMessageHandler } from "@/services/rest/messages/account/a_po_6_2_cancel-withdrawal";
import { QUs111UserInfo } from "@/services/rest/messages/user/q_us_1_11_user-info";

export interface ErrorHandler {
    onLocalizedError (e: LocalizedError): void;

    onNotAuthorized (): void;
}

export interface NetworkErrorHandler {
    onNetworkError (code?: string): void;
}

export class SilentError extends Error {

}

export interface IRestService {
    session?: string;
    errorHandler?: ErrorHandler;
    networkErrorHandler?: NetworkErrorHandler;
    startApplication (countryId: number, locale: string): Promise<void>
    registerUser(phoneNumber: string, password: string): Promise<void>
    authorization (ad: AuthorizationData): Promise<User>
    casinoEnter (): Promise<void>
    casinoLeave (): Promise<void>;
    startGameFrame (demo: boolean): Promise<StartGameFrameResponse>
    closeGameFrame (): Promise<void>
    getExtendedBalance (): Promise<UserBalance | undefined>
    findVoucher (voucher: string): Promise<VoucherSellInfo>
    voucherDeposit (voucher: string): Promise<void>
    getPointList (pointTypeId: number, partnerList: number[]): Promise<Point[]>
    emailConfirmation (code: string): Promise<ConfirmationResult>
    withdrawal (accountNumber: string, orderSum: number,
                currencyId: number, confirmationMethod: ConfirmationMethod): Promise<string>
    resetPassword (phone: string): Promise<boolean>
    updateProfile (data: ProfileUpdateData): Promise<void>
    changePassword (currentPassword: string, newPassword: string): Promise<boolean>
    sendFile (fileEncoded: string, comment: string, name: string, contentType: string, size: number): Promise<void>
    getUserContent(): Promise<UserContent>
    getWithdrawalOrders (startDate: Date, endDate: Date): Promise<WithdrawalOrderEx[]>
    getOperations (operations: string[], startDate: Date, endDate: Date): Promise<Operation[]>
    cancelWithdrawal (orderGuid: string): Promise<void>
    getUser(): Promise<User>
}

export class RestService implements IRestService {
    session?: string;

    errorHandler?: ErrorHandler;
    networkErrorHandler?: NetworkErrorHandler;

    constructor (private readonly clockProvider: ClockProvider, private readonly settingsService: ISettingsService, private readonly appCode: string) {
    }

    private createXmlRequest (): XmlRequest {
        return new XmlRequest(this.appCode, this.clockProvider, this.session ? this.session : "", "ua");
    }

    protected async executeRequest<T> (handler: MessageHandler<T>, request?: XmlRequest): Promise<T> {
        const xmlRequest = request || this.createXmlRequest();
        xmlRequest.mtlRequestType = handler.mtlRequestType;
        handler.buildRequest(xmlRequest);
        const startRequestMark = xmlRequest.mtlRequestType + ". Start";
        const executeRequestMark = xmlRequest.mtlRequestType + ". Execute";
        const parseResponseMark = xmlRequest.mtlRequestType + ". Parse";
        const executeMeasureName = "measure request executing";
        const parseMeasureName = "measure request parsing";
        const allMeasureName = "measure request all";
        if (xmlRequest.needDebugLog) {
            performance.clearMarks();
            performance.clearMeasures();
            performance.mark(startRequestMark);
        }
        const response = await this.sendRequest(xmlRequest);
        if (xmlRequest.needDebugLog) {
            performance.mark(executeRequestMark);
        }
        const result = handler.parseResponse(response);
        if (xmlRequest.needDebugLog) {
            result.then(() => {
                performance.mark(parseResponseMark);
                performance.measure(executeMeasureName, startRequestMark, executeRequestMark);
                performance.measure(parseMeasureName, executeRequestMark, parseResponseMark);
                performance.measure(allMeasureName, startRequestMark, parseResponseMark);
                appLogger.logger.info(`Request ${xmlRequest.mtlRequestType}:\t
                executing:${performance.getEntriesByName(executeMeasureName)[0].duration} ms\t
                parsing:${performance.getEntriesByName(parseMeasureName)[0].duration} ms\t
                all:${performance.getEntriesByName(allMeasureName)[0].duration} ms`
                );
            });
        }
        result.catch((e) => {
            if (e instanceof LocalizedError) {
                if (this.errorHandler) {
                    this.errorHandler.onLocalizedError(e);
                }
            }
            if (e instanceof NotAuthorizedError) {
                if (this.errorHandler) {
                    this.errorHandler.onNotAuthorized();
                }
            }
        });
        return result;
    }

    protected async sendRequest (request: XmlRequest): Promise<string> {
        const url = (process.env.NODE_ENV !== "production") ? "https://www.dev.tubetgol.com/" : "/";
        const config: AxiosRequestConfig = {
            baseURL: url,
            headers: { "Content-Type": "application/xml" },
            transformResponse: (res) => {
                return res;
            },
            responseType: "text"
        };
        if (request.needDebugLog) {
            appLogger.logger.info("SendRequest:", request.toString());
        }
        return Axios.post("/5.0/do.php?NOTGZ=1", request.toString(), config)
            .then(value => {
                if (request.needDebugLog) {
                    appLogger.logger.info("Response:", value.data);
                }
                return value.data;
            })
            .catch(reason => {
                if (this.networkErrorHandler) {
                    this.networkErrorHandler.onNetworkError(reason.code);
                }
                throw new Error(`MTL server have returned the error for request [${request.mtlRequestType}]: ${reason.message} (code: ${reason.code}, status: ${reason.status}). Server address: ${url}`);
            });
    }

    async startApplication (countryId: number, locale: string): Promise<void> {
        const xmlRequest = new XmlRequest(
            this.appCode,
            this.clockProvider,
            "00000000-0000-0000-0000-000000000000",
            locale);
        const handler = new AGe22ApplicationStartMessageHandler(countryId);
        const startResult = await this.executeRequest(handler, xmlRequest);
        this.session = startResult.sessionCode;
        this.settingsService.pointSettings = startResult.pointSettings;
    }

    async registerUser (phoneNumber: string, password: string): Promise<void> {
        const handler = new AUs23RegistrationMessageHandler(phoneNumber, password);
        return this.executeRequest(handler).then();
    }

    async authorization (ad: AuthorizationData): Promise<User> {
        const login = ad.phone ? ad.phone : ad.accountNumber;
        if (!login) {
            throw new Error("Authorization data not entered");
        }
        const handler = new AUs13Authorization(login, ad.password);
        const result = await this.executeRequest(handler);
        this.session = result.sessionCode;
        if (result.user === undefined) {
            throw new Error("User can't be null after success authorization");
        }
        return result.user;
    }

    async casinoEnter (): Promise<void> {
        const handler = new ACs29CasinoEnterMessageHandler();
        return this.executeRequest(handler);
    }

    async casinoLeave (): Promise<void> {
        const handler = new ACs210CasinoLeaveMessageHandler();
        return this.executeRequest(handler);
    }

    async startGameFrame (demo: boolean): Promise<StartGameFrameResponse> {
        const hostUrl = window.location.href;
        const handler = new ACs11GetGameFrameRequestHandler(demo, "RS_rls-lobby-lime", hostUrl);
        return this.executeRequest(handler);
    }

    async closeGameFrame (): Promise<void> {
        const handler = new ACs12CloseGameFrameRequestHandler("RS_rls-lobby-lime");
        return this.executeRequest(handler);
    }

    async getExtendedBalance (): Promise<UserBalance | undefined> {
        const handler = new QUs112ExtendedBalance();
        return this.executeRequest(handler);
    }

    async findVoucher (voucher: string): Promise<VoucherSellInfo> {
        const handler = new QVc11FindVoucherMessageHandler(voucher);
        return this.executeRequest(handler);
    }

    async voucherDeposit (voucher: string): Promise<void> {
        const handler = new AVc21DepositMessageHandler(voucher);
        return this.executeRequest(handler);
    }

    async getPointList (pointTypeId: number, partnerList: number[]): Promise<Point[]> {
        const handler = new QGe23PointListMessageHandler(pointTypeId, partnerList);
        return this.executeRequest(handler);
    }

    async emailConfirmation (code: string): Promise<ConfirmationResult> {
        const handler = new ACn11EmailConfirmation(code);
        const result = await this.executeRequest(handler);
        if (result.confirmationType === 1 && result.sessionCode) {
            this.session = result.sessionCode;
        }
        return result;
    }

    async withdrawal (accountNumber: string, orderSum: number,
        currencyId: number, confirmationMethod: ConfirmationMethod): Promise<string> {
        const handler = new APo68WithdrawalMessageHandler(accountNumber, orderSum, currencyId, confirmationMethod);
        return this.executeRequest(handler);
    }

    async resetPassword (phone: string): Promise<boolean> {
        const handler = new AUs15ResetPassword(phone);
        return this.executeRequest(handler);
    }

    async updateProfile (data: ProfileUpdateData): Promise<void> {
        const handler = new AUs14UpdateProfileMessageHandler(data);
        return this.executeRequest(handler);
    }

    async changePassword (currentPassword: string, newPassword: string): Promise<boolean> {
        const handler = new AUs16ChangePassword(currentPassword, newPassword);
        this.session = await this.executeRequest(handler);
        return true;
    }

    async sendFile (fileEncoded: string, comment: string, name: string, contentType: string, size: number): Promise<void> {
        const handler = new ACt11SendFileMessageHandler(fileEncoded, comment, name, contentType, size);
        return this.executeRequest(handler);
    }

    async getUserContent (): Promise<UserContent> {
        const handler = new QUs111UserContent();
        return this.executeRequest(handler);
    }

    async getWithdrawalOrders (startDate: Date, endDate: Date): Promise<WithdrawalOrderEx[]> {
        const handler = new QPo61WithdrawalOrders(startDate, endDate);
        return this.executeRequest(handler);
    }

    async getOperations (operations: string[], startDate: Date, endDate: Date): Promise<Operation[]> {
        const handler = new QPo11OperationsByDate(operations, startDate, endDate);
        return this.executeRequest(handler);
    }

    async cancelWithdrawal (orderGuid: string): Promise<void> {
        const handler = new APo62CancelWithdrawalMessageHandler(orderGuid);
        return this.executeRequest(handler);
    }

    async getUser (): Promise<User> {
        const handler = new QUs111UserInfo();
        return this.executeRequest(handler);
    }
}

<template>
    <template v-if="isConfirm">
        <div class="icons">
            <img :src="require(`@assets/icon-eye-${isPasswordVisible?'off':'on'}.png`)" alt=""
                 @click.prevent="switchPasswordVisible">
        </div>
        <Field id="confirmPassword" name="confirmPassword" :type="isPasswordVisible?'text':'password'" autocomplete="new-password" rules="min:5|confirmed:@password|invalidCharacters"/>
        <div class="field-error" v-if="errors.confirmPassword">{{errors.confirmPassword}}</div>
    </template>
    <template v-else>
        <div class="icons">
            <img :src="require(`@assets/icon-eye-${isPasswordVisible?'off':'on'}.png`)" alt=""
                 @click.prevent="isPasswordVisible = !isPasswordVisible" >
        </div>
        <Field id="password" name="password" :type="isPasswordVisible?'text':'password'" autocomplete="new-password" rules="min:5|required|invalidCharacters"/>
        <div class="field-error" v-if="errors.password">{{errors.password}}</div>
    </template>
</template>

<script src="./password-field-component.ts" />

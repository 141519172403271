<template>
    <div class="modal site-popup active" id="confirm-success"  v-if="user || withdrawalOrder || resetPasswordMessage">
        <div class="site-popup-container">
            <div class="title">{{$t("brand.name")}}</div>
            <div class="content">
                <div class="site-popup-form">
                    <div class="form-line" v-if="resetPasswordMessage">
                        <label>
                            {{resetPasswordMessage}}
                        </label>
                    </div>
                    <div class="info" v-if="user">
                        <div class="info-title">{{$t("webdesktop.emailConfirm.title")}}</div>
                        <div class="info-text">{{$t("webdesktop.emailConfirm.userNumber")}}</div>
                        <div class="info-value" id="userNumber">{{user.userNumber}}</div>
                        <div class="info-text">{{$t("webdesktop.emailConfirm.userLogin")}}</div>
                        <div class="info-value" id="userLogin">{{user.login}}</div>
                        <div class="text-title">{{$t("webdesktop.emailConfirm.ref1")}} <a @click.prevent="onCloseClick" id="confirm-success-ref">{{$t("webdesktop.emailConfirm.ref2")}}</a></div>
                    </div>
                    <div class="info" v-if="withdrawalOrder">
                        <div class="info-title">{{$t("webdesktop.withdrawalConfirm.title")}}{{withdrawalOrder.id}}</div>
                        <div class="info-text">{{$t("webdesktop.withdrawalConfirm.total")}}
                            {{withdrawalOrder.orderSum}}{{withdrawalOrder.currencyMark}}</div>
                        <div class="info-text">{{$t("webdesktop.withdrawalConfirm.withdrawalType")}}{{$t("webdesktop.withdrawalConfirm."+withdrawalOrder.withdrawalType, { brandName })}}</div>
                        <div class="info-text">{{$t("webdesktop.withdrawalConfirm.expiration")}}{{$d(withdrawalOrder.expirationTime, 'long')}}</div>
                        <div class="info-value">{{$t("webdesktop.withdrawalConfirm.payCode")}}{{formatVoucherNumber(withdrawalOrder.payCode)}}</div>
                    </div>
                </div>
                <div class="site-popup-buttons">
                    <button id="ok-button" type="submit" class="button" @click.prevent="onCloseClick">{{$t("common.forms.ok")}}</button>
                </div>
            </div>
        </div>
    </div>
    <div class="preloader" v-else>
        <div class="preloader-el"></div>
    </div>
</template>

<script src="./confirm-view-component.ts" />

import { AbstractMessageHandler } from "@/services/rest/messages/message-handler";
import { XmlRequest } from "@/services/rest/xml-request";
import { ProfileUpdateData } from "@/model/user-accounts";
import { getRequiredAttribute, getRequiredChild } from "@/utils/xml-helper-functions";
import { MtlError, NotAuthorizedError } from "@/services/rest/errors";

export class AUs14UpdateProfileMessageHandler extends AbstractMessageHandler<void> {
    protected readonly requestType: string = "A.US.1.4";

    constructor (readonly data: ProfileUpdateData) {
        super();
    }

    buildRequest (request: XmlRequest) {
        const actionElement = request.addChild(request.body, "action", {
            type: this.requestType
        });
        if (this.data.firstName) {
            request.addChildWithValue(actionElement, "FirstName", this.data.firstName);
        }
        if (this.data.lastName) {
            request.addChildWithValue(actionElement, "LastName", this.data.lastName);
        }
        if (this.data.middleName) {
            request.addChildWithValue(actionElement, "MiddleName", this.data.middleName);
        }
        if (this.data.login) {
            request.addChildWithValue(actionElement, "Login", this.data.login);
        }
        if (this.data.birthday) {
            request.addChildWithValue(actionElement, "Birthday", this.data.birthday.toString());
        }
    }
    // eslint-disable-next-line
    parseMessageBody (body: Element, _head: Element): void {
        const action = getRequiredChild(body, "action");
        const serverCode = getRequiredAttribute(action, "servercode");
        if (serverCode === "1001" || serverCode === "1313") {
            throw new NotAuthorizedError();
        }
        if (serverCode === "1150") {
            return;
        }
        throw new MtlError(serverCode);
    }
}

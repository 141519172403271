import { AbstractMessageHandler } from "@/services/rest/messages/message-handler";
import {
    getChild, getRequiredAttribute,
    getRequiredChild, getRequiredFloatAttribute, getRequiredIntAttribute,
    getRequiredIntValue,
    getRequiredValue,
    getValue
} from "@/utils/xml-helper-functions";
import { PaymentSystemData, PaymentSystems } from "@/model/payment-systems";
import { PointSettings, PointSettingsImpl } from "@/model/point-settings";
import { Currency } from "@/utils/currency";

export abstract class AbstractPointSettingsMessageHandler<T> extends AbstractMessageHandler<T> {
    protected parsePoint (actionElement: Element): PointSettings {
        const pointElement = getRequiredChild(actionElement, "Point");
        const pointGuid = getRequiredValue(getRequiredChild(pointElement, "PointGUID"));
        const pointId = getRequiredIntValue(getRequiredChild(pointElement, "PointId"));
        const partnerId = getRequiredIntValue(getRequiredChild(pointElement, "PartnerId"));
        const pointName = getRequiredValue(getRequiredChild(pointElement, "Name"));
        const pointCityText = getRequiredValue(getRequiredChild(pointElement, "CityText"));
        const pointAddress = getValue(getRequiredChild(pointElement, "Address"));
        const pointPhone = getValue(getRequiredChild(pointElement, "Phone"));
        const pointEmail = getValue(getRequiredChild(pointElement, "Email"));
        const stationId = getRequiredIntValue(getRequiredChild(pointElement, "StationId"));
        const currencyElement = getRequiredChild(pointElement, "Currency");
        const currency: Currency = {
            rateId: getRequiredIntAttribute(currencyElement, "rateId"),
            typeId: getRequiredIntAttribute(currencyElement, "typeId"),
            value: getRequiredFloatAttribute(currencyElement, "value")
        };
        const pointConfigListElement = getRequiredChild(pointElement, "PointConfigList");
        const config = new Map<string, string>();
        const configElements = pointConfigListElement.getElementsByTagName("Item");
        for (let i = 0; i < configElements.length; i++) {
            const itemElement = configElements.item(i);
            if (itemElement != null) {
                const key = getRequiredAttribute(itemElement, "type");
                const value = itemElement.textContent;
                if (value) {
                    config.set(key, value);
                }
            }
        }
        const paymentSystems: PaymentSystemData[] = parsePaymentSystemList(pointElement);
        const result = new PointSettingsImpl(pointId, pointGuid, pointName, pointCityText, pointAddress, pointPhone, pointEmail, currency, config, stationId, partnerId, paymentSystems);
        return result;
    }
}

export function parsePaymentSystemList (pointElement: Element): PaymentSystemData[] {
    const paymentSystems = [];
    const paymentSystemListElement = getChild(pointElement, "PaySystemList");
    if (paymentSystemListElement) {
        const paymentSystemElements = paymentSystemListElement.getElementsByTagName("Item");
        for (let i = 0; i < paymentSystemElements.length; i++) {
            const itemElement = paymentSystemElements.item(i);
            if (itemElement !== null) {
                const currencyList = JSON.parse(getRequiredAttribute(itemElement, "allowCurrency"));
                paymentSystems.push({
                    paymentSystemType: getRequiredAttribute(itemElement, "mark") as PaymentSystems,
                    availableCurrencies: currencyList
                });
            }
        }
    }
    return paymentSystems;
}
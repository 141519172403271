<template>
    <Transition name="fade" mode="out-in">
        <div v-if="show" class="modal modal-description">
            <div class="modal-content">
                <h2 v-if="title">{{ title }}</h2>
                <div>
                    <slot/>
                </div>
                <div class="form-line modal-buttons">
                    <button type="button" @click="$emit('update:show', false)" class="button">{{$t("common.forms.close")}}</button>
                </div>
            </div>
        </div>
    </Transition>
</template>

<script src="./modal-description-component.ts"/>

<style src="@scss/modal.scss" lang="scss" scoped/>

import { onMounted, ref } from "vue";
import PartnerWithdrawalDialog
    from "@/views/withdrawal-view/partner-withdrawal-dialog/PartnerWithdrawalDialog.vue";
import { useRestService, useSettingsService } from "@/services";
import { Point } from "@/model/point";
enum ModalDialogs {
    PartnerWithdrawal = "PartnerWithdrawalDialog",
    None = ""
}

export default {
    name: "WithdrawalView",
    components: {
        PartnerWithdrawalDialog
    },
    setup () {
        const activeDialog = ref<ModalDialogs>(ModalDialogs.None);
        const isInitialized = ref(false);
        const restStervice = useRestService();
        const settings = useSettingsService().pointSettings;
        const pointList = ref<Point[]>([]);

        onMounted(async () => {
            pointList.value = await restStervice.getPointList(2, [settings.partnerId]);
            isInitialized.value = true;
        });

        function onModalDialogClose () {
            activeDialog.value = ModalDialogs.None;
        }

        function onModalDialogOpen (dialog: ModalDialogs) {
            activeDialog.value = dialog;
        }
        return {
            ModalDialogs,
            activeDialog,
            isInitialized,
            pointList,
            onModalDialogClose,
            onModalDialogOpen
        };
    }
};
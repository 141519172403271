import { ROUTES } from "@/router/route-consts";

export default {
    name: "Halls",
    setup () {
        const halls = [
            { name: "Братислава", disabled: false },
            { name: "Супутник", disabled: true },
            { name: "Кремінь", disabled: true },
            { name: "Прикарпаття", disabled: true },
            { name: "Ribas Rooms Lutsk", disabled: true },
            { name: "Apartel Uzhhorod", disabled: true }
        ];
        return {
            halls,
            ROUTES
        };
    }
};

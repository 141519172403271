import FinancesTabs from "@/views/finances/tabs/FinancesTabs.vue";
import { computed, onMounted, ref } from "vue";
import { EWithdrawalStatus, WithdrawalOrderEx } from "@/model/withdrawal";
import { useI18n } from "vue-i18n";
import { useRestService } from "@/services";
import { showMessageBox } from "@/components/messagebox/message-box-component";
import ConfirmationDialog from "@/components/confirmation-dialog/ConfirmationDialog.vue";

export default {
    name: "WithdrawalOrder",
    components: { FinancesTabs, ConfirmationDialog },
    setup () {
        const restService = useRestService();
        const requestPending = ref(false);
        const withdrawalOrders = ref<WithdrawalOrderEx[]>([]);
        const guidToCancel = ref<string>();
        const i18n = useI18n();
        const itemsPerPageRef = ref<number>(10);
        const currentPage = ref<number>(1);
        const startDate = new Date();
        startDate.setMonth(startDate.getMonth() - 1);
        const startDateRef = ref(startDate.toISOString().split("T")[0]);
        const endDateRef = ref(new Date().toISOString().split("T")[0]);

        const withdrawalOrdersPaged = computed(() => {
            const offset = (currentPage.value - 1) * itemsPerPageRef.value;
            return withdrawalOrders.value.slice(offset).slice(0, itemsPerPageRef.value);
        });

        const totalPages = computed(() => {
            return Math.ceil(withdrawalOrders.value.length / itemsPerPageRef.value);
        });

        function canCancel (order: WithdrawalOrderEx): boolean {
            return order.status === EWithdrawalStatus.ORDER_WITHDRAWAL_STATUS_WAITING_APPROVE ||
                order.status === EWithdrawalStatus.ORDER_WITHDRAWAL_STATUS_CASH_WAITING ||
                order.status === EWithdrawalStatus.ORDER_WITHDRAWAL_STATUS_WAITING_CONFIRMATION ||
                order.status === EWithdrawalStatus.ORDER_WITHDRAWAL_STATUS_APPROVED ||
                order.status === EWithdrawalStatus.ORDER_WITHDRAWAL_STATUS_APPROVED_UNVERIFIED;
        }

        onMounted(() => {
            dateChanged();
        });

        function dateChanged () {
            const startDate = new Date(startDateRef.value);
            const endDate = new Date(endDateRef.value);
            endDate.setTime(endDate.getTime() + (23 * 60 * 60 + 59 * 60 + 59) * 1000);
            startDate.setHours(0, 0, 0, 0);
            requestPending.value = true;
            restService.getWithdrawalOrders(startDate, endDate).then(r => {
                withdrawalOrders.value = r;
                requestPending.value = false;
            });
        }

        function cancelOrder (orderGUID: string) {
            guidToCancel.value = orderGUID;
        }

        function onCancelConfirmed () {
            if (guidToCancel.value) {
                const g = guidToCancel.value;
                restService.cancelWithdrawal(g).then(() => {
                    showMessageBox("success", undefined, i18n.t("webdesktop.withdrawalOrders.cancelSuccess"));
                    const order = withdrawalOrders.value.find(i => i.GUID === g);
                    if (order) {
                        order.status = EWithdrawalStatus.ORDER_WITHDRAWAL_STATUS_USER_CANCELLED;
                    }
                });
                guidToCancel.value = undefined;
            }
        }

        function getDescription (status: EWithdrawalStatus) {
            return "webdesktop.withdrawalOrders.statusText." + status;
        }

        return {
            requestPending,
            withdrawalOrders,
            EWithdrawalStatus,
            guidToCancel,
            withdrawalOrdersPaged,
            itemsPerPageRef,
            currentPage,
            startDateRef,
            endDateRef,
            totalPages,
            getDescription,
            onCancelConfirmed,
            dateChanged,
            cancelOrder,
            canCancel
        };
    }
};

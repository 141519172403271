<template>
    <div class="transaction">
        <div class="transaction-container">
            <h3 class="title">
                {{$t("header.withdrawal")}}
            </h3>

            <div class="item"  v-if="isInitialized">
                <div class="logo">
                    <div class="head">
                        Ваучер IPay
                    </div>
                    <div class="items">
                        <img src="/payment/sq_Ipay.svg" alt="">
                    </div>
                </div>
                <div class="info">
                    <div class="head">Комісія, 0%</div>
                    <div class="body">
                        Наразі зняття можливе лише в касі залу гральних автоматів<br>
                        Адреси залів:
                        <ul>
                            <li v-for="point of pointList" :key="point.id"> {{point.cityName}}, {{point.address}}</li>
                        </ul>
                    </div>
                    <button class="button" @click="onModalDialogOpen(ModalDialogs.PartnerWithdrawal)" data-testid="partner-withdrawal">Зняти</button>
                </div>
            </div>
        </div>
    </div>
    <component v-if="activeDialog !== ModalDialogs.None" :is="activeDialog" @close="onModalDialogClose" @openDialog="onModalDialogOpen"
               @submit="onModalDialogClose"/>
</template>

<script src="./withdrawal-view-component.ts">
</script>

<style scoped>

</style>

import { CLOSE_EVENT, EVENT_SUCCESS } from "@/utils/events";
import { ref, SetupContext } from "vue";
import { useRestService } from "@/services";

export const CODE_ERROR_EVENT = "onCodeError";
export default {
    name: "ConfirmCodeDialog",
    emits: [CLOSE_EVENT, EVENT_SUCCESS, CODE_ERROR_EVENT],
    setup (props: unknown, context: SetupContext) {
        const confirmCodeRef = ref();
        const restService = useRestService();
        function onClose () {
            context.emit(CLOSE_EVENT);
        }

        function onSubmit () {
            restService.emailConfirmation(confirmCodeRef.value).then((result) => {
                context.emit(EVENT_SUCCESS, result);
            }).catch(e => context.emit(CODE_ERROR_EVENT, e.message));
        }

        return {
            confirmCodeRef,

            onSubmit,
            onClose
        };
    }
};

export interface Logger {
    name: string;

    trace (...data: unknown[]): void;

    debug (...data: unknown[]): void;

    info (message: string, ...data: unknown[]): void;

    warn (message: string, e?: Error, ...data: unknown[]): void;

    error (message: string, e?: Error, ...data: unknown[]): void;
}

export class ConsoleLogger implements Logger {
    name: string = "WebConsole logger";

    trace (...data: unknown[]): void {
        if (process.env.NODE_ENV !== "production") {
            console.trace(...data);
        }
    }

    debug (...data: unknown[]): void {
        if (process.env.NODE_ENV !== "production") {
            console.debug(...data);
        }
    }

    info (message: string, ...data: unknown[]) {
        console.log(message, ...data);
    }

    warn (message: string, e?: Error, ...data: unknown[]): void {
        console.warn(message, e ?? "", ...data);
    }

    error (message: string, e?: Error, ...data: unknown[]): void {
        console.error(message, e ?? "", ...data);
    }
}

export class AppLogger {
    logger: Logger = new ConsoleLogger();
}

export const appLogger = new AppLogger();

import { AbstractMessageHandler } from "@/services/rest/messages/message-handler";
import { XmlRequest } from "@/services/rest/xml-request";
import { getRequiredAttribute, getRequiredChild, getRequiredValue } from "@/utils/xml-helper-functions";
import { LoginResult, parseUser } from "@/services/rest/messages/user/login-result";
import { User } from "@/model/user-accounts";
import { MtlError } from "@/services/rest/errors";

export class AUs13Authorization extends AbstractMessageHandler<LoginResult> {
    protected readonly requestType: string = "A.US.1.3";

    constructor (readonly login: string, readonly password: string) {
        super();
    }

    buildRequest (request: XmlRequest) {
        const actionElement = request.addChild(request.body, "action", {
            type: this.requestType
        });
        request.addChildWithValue(actionElement, "UserNumber", this.login);
        request.addChildWithValue(actionElement, "Password", this.password);
        const selectorElement = request.addChild(actionElement, "Selector");
        request.addChild(selectorElement, "SelectObject", {
            class: "ps.user.UserContent",
            allFields: "true"
        });
    }
    // eslint-disable-next-line
    parseMessageBody (body: Element, _head: Element): LoginResult {
        const action = getRequiredChild(body, "action");
        const serverCode = getRequiredAttribute(action, "servercode");
        if (serverCode === "1140") {
            const sessionCode = getRequiredValue(getRequiredChild(_head, "sessioncode"));
            const userElement = getRequiredChild(action, "User");
            const user: User = parseUser(userElement);
            return {
                sessionCode,
                user
            };
        }
        throw new MtlError(serverCode);
    }
}

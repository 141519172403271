import { SetupContext } from "vue";
import { CLOSE_EVENT, SUBMIT_EVENT } from "@/utils/events";

export default {
    name: "ConfirmationDialog",
    emits: [CLOSE_EVENT, SUBMIT_EVENT],
    props: {
        message: { required: true, type: String },
        caption: { required: true, type: String }
    },
    setup (props: { readonly message: string }, context: SetupContext) {
        function onYesButtonClick () {
            context.emit(SUBMIT_EVENT);
        }
        function onNoButtonClick () {
            context.emit(CLOSE_EVENT);
        }

        return {
            onNoButtonClick,
            onYesButtonClick
        };
    }
};
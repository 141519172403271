import { CLOSE_EVENT } from "@/utils/events";
import { ref, SetupContext } from "vue";
import { useI18n } from "vue-i18n";
import { useBalanceStore } from "@/store/balance-store";
import { Point } from "@/model/point";
import { DialogState } from "@/views/deposit-view/voucher-deposit-dialog/dialog-state";
import { useRestService } from "@/services";
import { WithdrawalOrder } from "@/model/withdrawal";
import { showMessageBox } from "@/components/messagebox/message-box-component";
import {
    ConfirmationMethod,
    ConfirmationResult,
    ConfirmationType
} from "@/services/rest/messages/system/a_cn_1_1_confirmation";
import { formatVoucherNumber } from "@/utils/number-utils";
import ConfirmCodeDialog from "@/components/confirm-code-dialog/ConfirmCodeDialog.vue";
import { useUserStore } from "@/store/user-store";
import { EUserRole, hasRole } from "@/model/user-accounts";

export default {
    name: "PartnerWithdrawalDialog",
    emits: [CLOSE_EVENT],
    components: {
        ConfirmCodeDialog
    },
    setup (props: [], context: SetupContext) {
        const store = useBalanceStore();
        const userStore = useUserStore();
        const i18n = useI18n();
        const amountRef = ref<number>();
        const total = store.balanceTotal;
        const account = store.balance?.mainBalance?.primaryAccount;
        const currency = account?.currencyMark ?? "";
        const pointList = ref<Point[]>([]);
        const restService = useRestService();
        const dialogState = ref<DialogState>(DialogState.InputAmount);
        const withdrawalOrder = ref<WithdrawalOrder>();
        const confirmationMethod = ref<ConfirmationMethod>("phone");
        const minAmount = 40;

        function close () {
            context.emit(CLOSE_EVENT);
        }
        function submit () {
            if (dialogState.value === DialogState.InputAmount) {
                if (amountRef.value && amountRef.value < minAmount) {
                    showMessageBox("error", undefined, i18n.t("webdesktop.withdrawal.minAmountError", { minAmount: minAmount + currency }));
                    return;
                }
                if (!amountRef.value || amountRef.value === 0) {
                    return;
                }
                if (!account || !total || total < amountRef.value) {
                    showMessageBox("error", undefined, i18n.t("webdesktop.withdrawal.lowBalance"));
                    return;
                }
                if (hasRole(userStore.user, EUserRole.EXPRESS)) {
                    if (account && amountRef.value) {
                        restService.withdrawal(account.accountNumber, amountRef.value,
                            account.currencyId, "instant").then((code) => {
                            confirmationMethod.value = "instant";
                            restService.emailConfirmation(code).then(result => {
                                dialogState.value = DialogState.Finish;
                                onCodeSuccess(result);
                            });
                        });
                    }
                } else {
                    dialogState.value = DialogState.ConfirmationType;
                }
            } else if (dialogState.value === DialogState.ConfirmationType) {
                if (account && amountRef.value) {
                    restService.withdrawal(account.accountNumber, amountRef.value,
                        account.currencyId, confirmationMethod.value).then(() => {
                        dialogState.value = DialogState.CodeInput;
                    });
                }
            } else if (dialogState.value === DialogState.Finish) {
                close();
            }
        }
        function onConfirmCodeClose () {
            close();
        }
        function onCodeSuccess (result: ConfirmationResult) {
            if (result.confirmationType === ConfirmationType.Withdrawal) {
                dialogState.value = DialogState.Finish;
                withdrawalOrder.value = result.withdrawalOrder;
            }
        }
        function onCodeError (message: string) {
            showMessageBox("error", undefined, i18n.t(message));
        }
        return {
            amountRef,
            currency,
            pointList,
            dialogState,
            withdrawalOrder,
            onCodeError,
            close,
            submit,
            onConfirmCodeClose,
            onCodeSuccess,
            formatVoucherNumber
        };
    }
};

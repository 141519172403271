import { showMessageBox } from "@/components/messagebox/message-box-component";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { useRestService } from "@/services";
import { User } from "@/model/user-accounts";
import { ref } from "vue";
import { WithdrawalOrder } from "@/model/withdrawal";

export default {
    name: "EmailConfirmView",
    setup () {
        const route = useRoute();
        const router = useRouter();
        const i18n = useI18n();
        const restService = useRestService();
        const user = ref<User>();
        const withdrawalOrder = ref<WithdrawalOrder>();
        const resetPasswordMessage = ref("Операція пройшла успішно");
        if (route.query.code) {
            restService.emailConfirmation(route.query.code.toString()).then(confirmation => {
                if (confirmation.confirmationType === 1) {
                    // TODO: confirm user
                }
                if (confirmation.confirmationType === 3) {
                    resetPasswordMessage.value = i18n.t("resetPasswordDialog.success");
                }
                if (confirmation.confirmationType === 2) {
                    withdrawalOrder.value = confirmation.withdrawalOrder;
                }
            }).catch((error) => {
                showMessageBox("error", undefined, i18n.t(error.message), () => {
                    router.push({ path: "/" }).then();
                });
            });
        }

        function onCloseClick () {
            router.push({ name: "home" }).then();
        }
        return {
            user,
            withdrawalOrder,
            resetPasswordMessage,
            isCodePresent: route.query.code !== undefined,
            onCloseClick
        };
    }
};

<template>
    <div class="finances">
        <FinancesTabs/>
        <div class="finances-wrap">
            <div class="filters-line">
                <div class="select-wrap">
                    <label for="period-from">Період з:</label>
                    <input type="date" v-model="startDateRef" @input="dateChanged" id="period-from" name="period-from"  />
                </div>
                <div class="select-wrap">
                    <label for="period-from">до:</label>
                    <input type="date" v-model="endDateRef" @input="dateChanged" id="period-to" name="period-from"  />
                </div>
                <div class="select-wrap items-per-page mob-none">
                    <label >Рядків на сторінці:</label>
                    <select v-model="itemsPerPageRef">
                        <option value="" hidden></option>
                        <option :value="index * 10" :key="index" v-for="index in 5">{{ index * 10 }}</option>
                    </select>
                </div>
            </div>
            <div class="personal-pagination router-pagination mob-none" v-if="totalPages > 0">
                <a @click.prevent="currentPage = 1" class="arrow" :class="{disabled: currentPage === 1}">&lt;&lt;</a>
                <a @click.prevent="currentPage--" class="arrow" :class="{disabled: currentPage === 1}">&lt;</a>
                <ul class="personal-pagination-list">
                    <a @click.prevent="currentPage = index" :class="{active: index === 1}"  v-for="index in totalPages" :key="index">{{index}}</a>
                </ul>
                <a @click.prevent="currentPage++" class="arrow" :class="{disabled: currentPage === totalPages}">&gt;</a>
                <a @click.prevent="currentPage = totalPages" class="arrow" :class="{disabled: currentPage === totalPages}">&gt;&gt;</a>
            </div>
            <table class="personal-table">
                <thead class="personal-table-head">
                <tr>
                    <th>{{$t("finances.cols.date")}}</th>
                    <th>{{$t("finances.cols.amount")}}</th>
                    <th>{{$t("finances.cols.method")}}</th>
                    <th>{{$t("finances.cols.status")}}</th>
                    <th>{{$t("finances.cols.date")}}</th>
                </tr>
                </thead>
                <tbody class="personal-table-body">
                <tr  v-for="order of withdrawalOrdersPaged" :key="order.id">
                    <td><small>{{ $d(order.orderTime, 'long') }}</small></td>
                    <td><b>{{ order.sumOrder.toFixed(2) }} {{order.currencyMark}}</b></td>
                    <td>
                        <template v-if="order.withdrawalType">
                            {{ $t("webdesktop.withdrawalOrders.wt." + order.withdrawalType) }}
                        </template>
                    </td>
                    <td>
                        {{$t(getDescription(order.status))}}<br>
                        {{$t("webdesktop.withdrawalOrders.payCode")}}: {{ order.payCode }}
                    </td>
                    <td>
                        <a
                            v-if="canCancel(order)"
                            class="personal-table-cancel"
                            href="#" @click.prevent="cancelOrder(order.GUID)"
                        >{{$t("webdesktop.withdrawalOrders.cancel")}}</a>
                        <small v-else>{{$d(order.changeStatusTime, 'long')}}</small>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>

    <confirmation-dialog v-if="guidToCancel" :caption="$t('webdesktop.withdrawalOrders.confirm.caption')"
                         :message="$t('webdesktop.withdrawalOrders.confirm.text')" @submit="onCancelConfirmed" @close="guidToCancel = undefined"></confirmation-dialog>
</template>

<script src="./withdrawal-orders-component.ts"/>

<style scoped>

</style>

import { Form, Field } from "vee-validate";
import CountrySelect from "@/components/country-select/CountrySelect.vue";
import PasswordField from "@/components/password-field/PasswordField.vue";
import { computed, ref } from "vue";
import { countries } from "@/model/consts/countries";
import { onNaturalNumberKeyFilter } from "@/utils/form-validation";
import { ROUTES } from "@/router/route-consts";
import ModalDescription from "@/components/modal/ModalDescription.vue";
import ResetPasswordDialog from "@/components/reset-password-dialog/ResetPasswordDialog.vue";
import { useRestService } from "@/services";
import { AuthorizationData } from "@/model/authorization-data";
import { MtlError } from "@/services/rest/errors";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useUserStore } from "@/store/user-store";
import { showMessageBox } from "@/components/messagebox/message-box-component";
import Cookies from "js-cookie";
import { SESSION_COOKIE } from "@/consts/cookies-consts";

interface LoginFormErrors {
    phone: string | undefined
}

export default {
    name: "LoginView",
    components: {
        Form, Field, CountrySelect, PasswordField, ModalDescription, ResetPasswordDialog
    },
    props: {
        showForm: {
            type: Boolean,
            default: false
        }
    },
    setup () {
        const i18n = useI18n();
        const router = useRouter();
        const showModal = ref(false);
        const countryId = ref<number>(217);
        const phoneCode = computed(() => {
            return countryId.value ? countries.getById(countryId.value)?.phoneCode : "";
        });
        const restService = useRestService();
        const userStore = useUserStore();
        const isInitialized = ref(false);
        const isResetPasswordDialogVisible = ref(false);

        const isRequestPending = ref(false);
        const customErrors = ref<LoginFormErrors>({
            phone: undefined
        });
        const tabActive = ref(0);

        function play () {
            router.push({ path: ROUTES.HALLS.path }).then();
        }

        function onSubmit (data: AuthorizationData) {
            if (data.phone) {
                data.phone = phoneCode.value + data.phone;
            }
            isRequestPending.value = true;
            restService.authorization(data).then((u) => {
                isRequestPending.value = false;
                userStore.user = u;
                if (restService.session) {
                    Cookies.set(SESSION_COOKIE, restService.session);
                }
                if (data.password.length < 8) {
                    showMessageBox("success", undefined, i18n.t("tempPassword"));
                }
                play();
            }).catch(e => {
                isRequestPending.value = false;
                if (e instanceof MtlError) {
                    const serverCode = (e as MtlError).code;
                    const message = i18n.t(`errors.mtl.code${serverCode}`);
                    console.log(message);
                    switch (serverCode) {
                        case "1122":
                        {
                            customErrors.value.phone = message;
                            document.getElementById("phone")?.focus({ preventScroll: false });
                            break;
                        }
                    }
                }
            });
        }
        return {
            showModal,
            countryId,
            phoneCode,
            customErrors,
            isInitialized,
            isResetPasswordDialogVisible,
            ROUTES,
            onSubmit,
            play,
            onPhoneKeyDown: onNaturalNumberKeyFilter,
            tabActive
        };
    }
};

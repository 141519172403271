export interface ClockProvider {
    getDate (): Date;
}

export class DefaultClockProvider implements ClockProvider {
    getDate (): Date {
        return new Date();
    }
}

export class TimeoutController {
    private timeout?: number;

    setTimeout (handler: TimerHandler, timeout?: number) {
        this.clear();
        this.timeout = setTimeout(handler, timeout);
    }

    clear () {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }
}

export function dateToString (clockProvider: ClockProvider) {
    return clockProvider.getDate().toISOString();
}

export async function timeout (duration: number): Promise<void> {
    return new Promise(resolve => {
        setTimeout(resolve, duration);
    });
}

export function parseDate (dateStr: string): Date {
    const year = dateStr.substr(0, 4);
    const month = dateStr.substr(4, 2);
    const day = dateStr.substr(6, 2);
    const hour = dateStr.substr(8, 2);
    const minute = dateStr.substr(10, 2);
    const second = dateStr.substr(12, 2);
    return new Date(Date.UTC(Number.parseInt(year), Number.parseInt(month) - 1, Number.parseInt(day),
        Number.parseInt(hour), Number.parseInt(minute), Number.parseInt(second)));
}

export function parseClientDate (dateStr: string): Date {
    const year = dateStr.substr(0, 4);
    const month = dateStr.substr(4, 2);
    const day = dateStr.substr(6, 2);
    const hour = dateStr.substr(8, 2);
    const minute = dateStr.substr(10, 2);
    const second = dateStr.substr(12, 2);
    return new Date(Number.parseInt(year), Number.parseInt(month) - 1, Number.parseInt(day),
        Number.parseInt(hour), Number.parseInt(minute), Number.parseInt(second));
}

export function toLocalTime (date: Date): Date {
    const nowUtc = Date.UTC(date.getFullYear(), date.getMonth(),
        date.getDate(), date.getHours(),
        date.getMinutes(), date.getSeconds());
    return new Date(nowUtc);
}

export function dateToUtcMtlStr (d: Date): string {
    return d.getUTCFullYear().toString() + addLeadingZero((d.getUTCMonth() + 1).toString()) +
        addLeadingZero(d.getUTCDate().toString()) + addLeadingZero(d.getUTCHours().toString()) +
        addLeadingZero(d.getUTCMinutes().toString()) + addLeadingZero(d.getUTCSeconds().toString());
}

export function dateToMtlStr (d: Date): string {
    const localeString = d.toLocaleString("en-US", { timeZone: "Europe/Kiev" });
    const date = new Date(localeString);
    return date.getFullYear().toString() + addLeadingZero((date.getMonth() + 1).toString()) +
        addLeadingZero(date.getDate().toString()) + addLeadingZero(date.getHours().toString()) +
        addLeadingZero(date.getMinutes().toString()) + addLeadingZero(date.getSeconds().toString());
}

export function dateToSimpleStr (d: Date) {
    return d.getFullYear().toString() + "." + addLeadingZero((d.getMonth() + 1).toString()) + "." +
        addLeadingZero(d.getDate().toString());
}

export function addDays (date: Date, days: number) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}

export function addHours (date: Date, hours: number) {
    const result = new Date(date);
    result.setHours(result.getHours() + hours);
    return result;
}

export function addLeadingZero (str: string): string {
    if (str.length === 2) {
        return str;
    }
    return "0" + str;
}

export function formatForMaxDate (d: Date): string {
    return [d.getFullYear(), addLeadingZero((d.getMonth() + 1).toString()), addLeadingZero(d.getDate().toString())].join("-");
}

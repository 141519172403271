<template>
    <div class="modal site-popup active profile-dialog">
        <div class="site-popup-container modal-content">
            <div class="popup-close modal-close d-none"></div>
            <div class="title">Title</div>
            <div class="content">
                <div class="site-popup-form">
                    <div class="form-line flex-column line-wrap">
                        <label class="nowrap">Label</label>
                        <input type="text">
                    </div>
                    <div class="form-line flex-column line-wrap">
                        <label class="nowrap">Label</label>
                        <input type="text">
                    </div>
                </div>
                <div class="site-popup-buttons">
                    <button class="button button-secondary">{{$t("common.forms.cancel")}}</button>
                    <a href="" class="button button-general">{{$t("common.forms.continue")}}</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="./profile-view-dialog-component.ts"/>

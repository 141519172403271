import { AbstractMessageHandler } from "@/services/rest/messages/message-handler";
import { XmlRequest } from "@/services/rest/xml-request";
import { getRequiredAttribute, getRequiredChild, getValue } from "@/utils/xml-helper-functions";
import { LocalizedError } from "@/services/rest/errors";

export class AUs16ChangePassword extends AbstractMessageHandler<string> {
    protected readonly requestType: string = "A.US.1.6";

    constructor (readonly currentPassword: string, readonly newPassword: string) {
        super();
    }

    buildRequest (request: XmlRequest) {
        const actionElement = request.addChild(request.body, "action", {
            type: this.requestType
        });
        request.addChildWithValue(actionElement, "CurrentPassword", this.currentPassword);
        request.addChildWithValue(actionElement, "NewPassword", this.newPassword);
    }
    // eslint-disable-next-line
    parseMessageBody (body: Element, _head: Element): string {
        const action = getRequiredChild(body, "action");
        const serverCode = getRequiredAttribute(action, "servercode");
        if (serverCode === "1170") {
            return getValue(getRequiredChild(_head, "sessioncode"));
        }
        throw new LocalizedError(`errors.mtl.code${serverCode}`);
    }
}
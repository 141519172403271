import { App, Plugin } from "vue";
import { use } from "@/utils/dependency-injection";

export interface StreamServer {
    readonly locale: string;
    readonly address: string;
}

export interface WebEnvironmentSettings {
    readonly appCode: string,
}

export async function requestEnvironmentSettings (devHost?: string): Promise<WebEnvironmentSettings> {
    const url = (process.env.__PRODUCTION__
        ? "/resource/app_properties.json"
        : `https://${devHost ?? "www.dev.slots.4444.ua/"}/resource/app_properties.json`) + "?" + Math.random();
    const fetchResult = await fetch(url);
    const result: WebEnvironmentSettings = await fetchResult.json();
    return result;
}

export const webEnvironmentPlugin:Plugin = {
    install (app: App, settings: WebEnvironmentSettings) {
        app.provide(WEB_ENVIRONMENT, settings);
    }
};

export function useWebEnvironment ():WebEnvironmentSettings {
    return use(WEB_ENVIRONMENT);
}

const WEB_ENVIRONMENT = Symbol("WEB_ENVIRONMENT");

import { RouteLocationNormalizedLoaded, useRoute } from "vue-router";
import { computed } from "vue";
import { ROUTES } from "@/router/route-consts";

export interface Tab {
    id: number;
    path: string;
    i18nKey: string;
    additionPaths?: string[];
    icon?: string;
}

export function recognizeDefaultTabKey (route: RouteLocationNormalizedLoaded, t: Tab[]): Tab | undefined {
    console.log(t);
    for (const tab of t) {
        if (route.path.indexOf(tab.path) > 0) {
            return tab;
        }
        if (tab.additionPaths) {
            for (const p of tab.additionPaths) {
                if (route.path.indexOf(p) > 0) {
                    return tab;
                }
            }
        }
    }
    return undefined;
}

export default {
    name: "StakesMenu",
    setup () {
        const route = useRoute();
        // Computed for recognize route changing
        const selectedTab = computed(() => recognizeDefaultTabKey(route, tabs));
        const tabs = [
            { id: 1, path: ROUTES.DEPOSIT_ORDERS.path, i18nKey: "finances.deposit" },
            { id: 2, path: ROUTES.WITHDRAWAL_ORDERS.path, i18nKey: "finances.withdrawal" }
        ];

        return {
            selectedTab,
            tabs
        };
    }
};

import { AbstractMessageHandler } from "@/services/rest/messages/message-handler";
import { XmlRequest } from "@/services/rest/xml-request";
import { getRequiredAttribute, getRequiredChild, getRequiredValue } from "@/utils/xml-helper-functions";

export type StartGameFrameResponse =
    | { type: "success"; url: string }
    | { type: "error"; code: string }

export class ACs11GetGameFrameRequestHandler extends AbstractMessageHandler<StartGameFrameResponse> {
    protected readonly requestType: string = "A.CS.1.1";

    constructor (private demo: boolean, private gameId: string, private hostUrl: string) {
        super();
    }

    buildRequest (request: XmlRequest): void {
        const actionElement = request.addChild(request.body, "action", {
            type: this.requestType
        });
        request.addChildWithValue(actionElement, "CasinoGameMark", this.gameId);
        request.addChildWithValue(actionElement, "siteUrl", this.hostUrl);
        request.addChildWithValue(actionElement, "Demo", this.demo ? "1" : "0");
    }

    protected parseMessageBody (body: Element): StartGameFrameResponse {
        const action = getRequiredChild(body, "action");
        const serverCode = getRequiredAttribute(action, "servercode");
        if (serverCode === "8100") {
            const gameUrl = getRequiredChild(action, "GameURL");
            return {
                type: "success",
                url: getRequiredValue(gameUrl)
            };
        } else {
            return {
                type: "error",
                code: serverCode
            };
        }
    }
}

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { i18n } from "@/i18n/index";
import { RestService } from "@/services/rest/rest-service";
import { DefaultClockProvider } from "@/utils/time-utils";
import { services } from "@/services";
import { createPinia } from "pinia";
import { SettingsServiceImpl } from "@/services/settings/settings-service";
import { requestEnvironmentSettings, webEnvironmentPlugin } from "@/services/settings/web-environment";

async function run () {
    const environment = await requestEnvironmentSettings();
    const app = createApp(App).use(i18n).use(createPinia()).use(router).use(webEnvironmentPlugin, environment);
    const settingsService = new SettingsServiceImpl();
    const restService = new RestService(new DefaultClockProvider(), settingsService, environment.appCode);

    app.provide(services.SETTINGS, settingsService);
    app.provide(services.REST, restService);
    app.mount("#app");
}

run().then();
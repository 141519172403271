<template>
    <div class="modal site-popup active" id="voucher-input-dialog">
        <div class="site-popup-container modal-content">
            <div class="popup-close modal-close d-none" @click.prevent="close"></div>
            <div class="title">{{$t("webdesktop.deposit.voucher.caption")}}</div>
            <div class="content">
                <div class="site-popup-form">
                    <div class="part">
                        <div class="site-popup-text" v-if="dialogState === 4">
                            {{$t("webdesktop.deposit.voucher.success",
                            {
                                code: voucherNumber,
                                amount: voucherRef.amount +
                                    voucherRef.currency
                            })}}
                        </div>
                        <div class="site-popup-text" v-if="dialogState === 2">
                            {{$t("webdesktop.deposit.voucher.found",
                            {
                                code: voucherNumber,
                                amount: voucherRef.amount +
                                        voucherRef.currency
                            })}}
                        </div>
                        <div class="form-line flex-column line-wrap" v-if="dialogState === 1">
                            <label for="voucher-input" class="nowrap">{{$t("webdesktop.deposit.voucher.numberLabel")}}</label>
                            <input type="text" id="voucher-input" v-model="voucherNumber">
                        </div>
                    </div>
                </div>
                <div class="site-popup-buttons">
                    <button class="button button-secondary" @click.prevent="close" v-if="dialogState === 1 || dialogState === 2">{{$t("common.forms.cancel")}}</button>
                    <a href="" class="button button-general" @click.prevent="submit" :class="{disabled: !voucherNumber || voucherNumber.toString().length !== 12 || requestPending}">{{$t("common.forms.continue")}}</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="./voucher-deposit-dialog-component.ts" />

export interface Currency {
    rateId: number;
    typeId: number;
    value: number;
}

export function getCurrencyName (id: number): string {
    switch (id) {
        case 1:
            return "UAH";
        case 2:
            return "USD";
        case 3:
            return "RUR";
        case 4:
            return "EEK";
        case 5:
            return "BYR";
        case 6:
            return "";
        case 7:
            return "";
        case 8:
            return "EUR";
        case 9:
            return "GEL";
        case 11:
            return "AMD";
        case 12:
            return "AZN";
        case 13:
            return "KZT";
        case 14:
            return "COP";
        case 15:
            return "PEN";
        case 16:
            return "MDL";
        case 17:
            return "MXN";
        case 18:
            return "BRL";
        case 19:
            return "CLP";
        case 20:
            return "CRC";
        case 21:
            return "PNT";
        case 23:
            return "LTC";
        case 24:
            return "ETH";
        case 25:
            return "XRP";
        case 22:
            return "BTC";
        case 26:
            return "BCH";
        case 27:
            return "BTG";
        case 28:
            return "ZEC";
        case 29:
            return "DASH";
        case 30:
            return "PYG";
        case 31:
            return "BOB";
        case 32:
            return "XAF";
        case 33:
            return "UYU";
        case 34:
            return "HTG";
        case 35:
            return "NHL";
        case 36:
            return "GBP";
        case 37:
            return "ZWL";
        case 38:
            return "TDD";
        case 39:
            return "ZAR";
        case 40:
            return "CLP";
        case 41:
            return "LSL";
        case 42:
            return "NAD";
        case 43:
            return "DOP";
        case 44:
            return "ZMW";
        case 45:
            return "UGX";
        case 46:
            return "MWK";
        case 47:
            return "TZS";
        default:
            return "UNK";
    }
}

import { configure, defineRule } from "vee-validate";
import { confirmed, email, min, required, regex, digits } from "@vee-validate/rules";
import { Localization } from "@/i18n";

export function configureFormValidation (i18n: Localization) {
    configure({
        generateMessage: context => {
            if (context.rule) {
                switch (context.rule.name) {
                    case "required":
                        return i18n.t("common.forms.validation.required");
                    case "min": {
                        const params = context.rule.params;
                        if (params) {
                            return i18n.t("common.forms.validation.min", { param: params.toString() });
                        }
                        return "error";
                    }
                    case "digits": {
                        const params = context.rule.params;
                        if (params) {
                            return i18n.t("common.forms.validation.digits", { param: params.toString() });
                        }
                        return "error";
                    }
                    case "email":
                        return i18n.t("common.forms.validation.required");
                    case "confirmed":
                        return i18n.t("common.forms.validation.confirmed");
                    case "birthday":
                        return i18n.t("common.forms.validation.birthday");
                    case "invalidCharacters":
                        return i18n.t("common.forms.validation.invalidCharacters");
                    default: {
                        return i18n.t("common.forms.validation.invalidFormat");
                    }
                }
            }
            return `The field ${context.field} is invalid`;
        }
    });

    function calculateAge (birthday: Date) {
        const ageDifMs = Math.max(0, Date.now() - birthday.getTime());
        const ageDate = new Date(ageDifMs); // miliseconds from epoch
        return Math.abs(ageDate.getUTCFullYear() - 1970);
    }
    function birthdayValidator (value: string): boolean {
        const age = calculateAge(new Date(value));
        return age >= 18;
    }
    function invalidCharacters (value: string): boolean {
        return !/^.*[+-,;&].*$/.test(value);
    }

    defineRule("required", required);
    defineRule("min", min);
    defineRule("confirmed", confirmed);
    defineRule("email", email);
    defineRule("regex", regex);
    defineRule("birthday", birthdayValidator);
    defineRule("invalidCharacters", invalidCharacters);
    defineRule("digits", digits);
}

function isInvalidNumberInput (key: string): boolean {
    console.log(key);
    return key !== "0" && key !== "1" && key !== "2" && key !== "3" && key !== "4" &&
        key !== "5" && key !== "6" && key !== "7" && key !== "8" && key !== "9" &&
        key !== "Delete" && key !== "Backspace" && key !== "ArrowLeft" &&
        key !== "ArrowRight" && key !== "Home" && key !== "End" && key !== "Tab";
}

export function onPhoneNumberKeyFilter (event: KeyboardEvent) {
    if (isInvalidNumberInput(event.key)) {
        event.preventDefault();
    }
}

export function onPositiveNumberKeyFilter (event: KeyboardEvent) {
    if (isInvalidNumberInput(event.key) && event.key !== ".") {
        event.preventDefault();
    }
}

export function onNaturalNumberKeyFilter (event: KeyboardEvent) {
    if (isInvalidNumberInput(event.key)) {
        event.preventDefault();
    }
}

const re = /^[A-Za-z0-9]+$/;

export function onDocumentKeyFilter (event: KeyboardEvent) {
    const key = event.key;
    if (key === "Delete" || key === "Backspace" || key === "ArrowLeft" ||
        key === "ArrowRight" || key === "Home" || key === "End") {
        return;
    }
    if (!re.test(event.key)) {
        event.preventDefault();
    }
}
import { configureFormValidation } from "@/utils/form-validation";
import { useI18n } from "vue-i18n";
import MessageBox from "@/components/messagebox/MessageBox.vue";
import { showMessageBox, useMessageBox } from "@/components/messagebox/message-box-component";
import { useRestService } from "@/services";
import { LocalizedError } from "@/services/rest/errors";
import { useRoute, useRouter } from "vue-router";
import { useUserStore } from "@/store/user-store";
import { ROUTES } from "@/router/route-consts";
import { onMounted, ref } from "vue";
import Cookies from "js-cookie";
import { SESSION_COOKIE } from "@/consts/cookies-consts";

export default {
    components: {
        MessageBox
    },
    setup () {
        const i18n = useI18n();
        const restService = useRestService();
        const router = useRouter();
        const route = useRoute();
        const store = useUserStore();
        const isInitialized = ref(false);
        onMounted(() => {
            console.log(route.fullPath);
            if (route.fullPath.includes(ROUTES.CLOSE_SESSION.path)) {
                isInitialized.value = true;
                return;
            }
            restService.startApplication(217, "ua").then(() => {
                isInitialized.value = true;
            });
        });
        // useIdleDisconnect();
        restService.errorHandler = {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            onLocalizedError (e: LocalizedError) {
                const msg = e.params ? i18n.t(e.message, e.params) : i18n.t(e.message);
                showMessageBox("error", undefined, msg);
                if (msg.endsWith("code101")) {
                    store.user = undefined;
                    Cookies.remove(SESSION_COOKIE);
                    router.push({ name: ROUTES.LOGIN.name }).then();
                }
            },
            onNotAuthorized () {
                showMessageBox("error", "4444", i18n.t("errors.mtl.status.code4301"));
            }
        };
        configureFormValidation(i18n);
        const {
            caption,
            message,
            isMessageBoxActive,
            messageBoxType,
            messageBoxClose
        } = useMessageBox();

        return {
            isInitialized,
            caption,
            message,
            isMessageBoxActive,
            messageBoxType,
            messageBoxClose
        };
    }
};

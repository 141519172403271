import { WithdrawalOrder } from "@/model/withdrawal";
import { AbstractMessageHandler } from "@/services/rest/messages/message-handler";
import { XmlRequest } from "@/services/rest/xml-request";
import {
    getChild,
    getRequiredAttribute,
    getRequiredChild, getRequiredFloatValue,
    getRequiredIntValue, getRequiredValue,
    getValue
} from "@/utils/xml-helper-functions";
import { PaymentSystems } from "@/model/payment-systems";
import { parseDate } from "@/utils/time-utils";

export type ConfirmationMethod = "phone" | "email" | "instant";
export enum ConfirmationType {User = 1, Withdrawal = 2, ResetPassword = 3}

export type ConfirmationResultUser = {
    confirmationType: ConfirmationType.User,
    sessionCode: string;
}

export type ConfirmationResetPassword = {
    confirmationType: ConfirmationType.ResetPassword
}

export type ConfirmationResultWithdrawal = {
    confirmationType: ConfirmationType.Withdrawal,
    withdrawalOrder: WithdrawalOrder;
}

export type ConfirmationResult = ConfirmationResultUser | ConfirmationResultWithdrawal | ConfirmationResetPassword;

export class ACn11EmailConfirmation extends AbstractMessageHandler<ConfirmationResult> {
    protected readonly requestType: string = "A.CN.1.1";

    constructor (readonly code: string) {
        super();
    }

    buildRequest (request: XmlRequest) {
        const actionElement = request.addChild(request.body, "action", {
            type: this.requestType
        });
        request.addChildWithValue(actionElement, "ConfirmationCode", this.code);
    }
    // eslint-disable-next-line
    parseMessageBody (body: Element, _head: Element): ConfirmationResult {
        const action = getRequiredChild(body, "action");
        const serverCode = getRequiredAttribute(action, "servercode");

        function getWithdrawalType (wt: string): PaymentSystems {
            return wt as PaymentSystems;
        }
        if (serverCode === "1050") {
            const sessionElement = getChild(_head, "sessioncode");
            const sessionCode = getValue(sessionElement);
            let responseElement = getChild(action, "OrderWithdrawal");
            if (responseElement) {
                return {
                    confirmationType: 2,
                    withdrawalOrder:
                        {
                            id: getRequiredIntValue(getRequiredChild(responseElement, "OrderWithdrawalId")),
                            withdrawalType: getWithdrawalType(getValue(getRequiredChild(responseElement, "WithdrawalType"))),
                            currencyId: getRequiredIntValue(getRequiredChild(responseElement, "CurrencyId")),
                            currencyMark: getRequiredValue(getRequiredChild(responseElement, "CurrencyMark")),
                            payCode: getRequiredValue(getRequiredChild(responseElement, "PayCode")),
                            expirationTime: parseDate(getRequiredValue(getRequiredChild(responseElement, "ExpirationTime"))),
                            orderSum: getRequiredFloatValue(getRequiredChild(responseElement, "OrderSum"))
                        }
                };
            }
            responseElement = getChild(action, "UserRegistration");
            if (!responseElement) {
                responseElement = getChild(action, "UserApplyChangeContact");
            }
            if (!responseElement) {
                getRequiredChild(action, "UserForgotPassword");
                return {
                    confirmationType: 3,
                    sessionCode: ""
                };
            }
            return {
                confirmationType: 1,
                sessionCode
            };
        }
        throw new Error(`errors.mtl.code${serverCode}`);
    }
}

import { Currency } from "@/utils/currency";
import { isPaymentSystemPresent, PaymentSystemData, PaymentSystems } from "@/model/payment-systems";

export interface PointSettings {
    readonly id: number;
    name: string;
    address: string;
    readonly stationId: number;
    readonly partnerId: number;
    currency: Currency;
    readonly paymentSystems: PaymentSystemData[];
    isPaymentSystemAvailable (paymentSystem: PaymentSystems): boolean;
}

export const defaultPointSettings: PointSettings = {
    id: 0,
    name: "",
    address: "",
    stationId: 0,
    currency: {
        typeId: 0,
        rateId: 0,
        value: 0
    },
    partnerId: 0,
    paymentSystems: [],
    isPaymentSystemAvailable (): boolean {
        return true;
    }
};

export class PointSettingsImpl implements PointSettings {
    private readonly SCALE = 2;
    readonly paymentSystems: PaymentSystemData[];
    constructor (readonly id: number,
        readonly pointGUID: string,
        readonly name: string,
        readonly cityText: string,
        readonly address: string,
        readonly phone: string,
        readonly email: string,
        readonly currency: Currency,
        readonly config: Map<string, string>,
        readonly stationId: number,
        readonly partnerId: number,
        paymentSystems: PaymentSystemData[]
    ) {
        this.paymentSystems = paymentSystems;
    }

    isPaymentSystemAvailable (paymentSystem: PaymentSystems): boolean {
        return isPaymentSystemPresent(this.paymentSystems, paymentSystem);
    }

    private getProperty (name: string): string | undefined {
        return this.config.get(name);
    }

    private getFloatProperty (name: string): number {
        const val = this.getProperty(name);
        return val ? parseFloat(val) : 0;
    }

    private getBooleanProperty (name: string): boolean {
        const val = this.getProperty(name);
        return val === "true";
    }
}

<template>
    <div class="messagebox" :class="[type]">
        <div class="messagebox-container">
            <div class="messagebox-content">
                <h3 v-if="title" class="title">{{title}}</h3>
                <p class="description" data-testid="message-text">
                    {{description}}
                </p>
            </div>
            <button class="button button-general" @click="handleClose">{{$t("common.forms.close")}}</button>
        </div>
    </div>
</template>

<script src="./message-box-component.ts" lang="ts" />

import {
    getChild,
    getRequiredAttribute, getRequiredChild,
    getRequiredIntAttribute,
    getRequiredIntValue, getRequiredValue,
    getValue
} from "@/utils/xml-helper-functions";
import { toLocalTime } from "@/utils/time-utils";
import { EUserRole, User, UserSession } from "@/model/user-accounts";

function checkZeroBirthday (birthday: string): string {
    return birthday === "0000-00-00" ? "" : birthday;
}
export function parseUser (userElement: Element): User {
    const registrationTimeElement = getChild(userElement, "RegistrationTime");
    const userIdElement = getChild(userElement, "UserId");
    const cityElement = getChild(userElement, "CityText");
    const sessions: UserSession[] = [];
    const sessionsElement = getChild(userElement, "UserSessions");
    if (sessionsElement !== undefined) {
        for (let sessionElement: Element | null = sessionsElement.firstElementChild; sessionElement !== null; sessionElement = sessionElement?.nextElementSibling) {
            sessions.push({
                id: getRequiredIntAttribute(sessionElement, sessionsElement.hasAttribute("Id") ? "Id" : "sessionId"),
                ipAddress: getRequiredAttribute(sessionElement, "ipAddress"),
                registerTime: toLocalTime(new Date(getRequiredAttribute(sessionElement, "registerTime")))
            });
        }
    }
    const roles: EUserRole[] = [];
    const rolesElement = getChild(userElement, "UserRoleList");
    if (rolesElement !== undefined) {
        for (let roleElement: Element | null = rolesElement.firstElementChild; roleElement !== null; roleElement = roleElement?.nextElementSibling) {
            roles.push(getRequiredIntAttribute(roleElement, "Id") as EUserRole);
        }
    }
    const identificationCodeElement = getChild(userElement, "IdentificationCode");
    return {
        userId: userIdElement ? getRequiredIntValue(userIdElement) : 0,
        userNumber: getRequiredValue(getRequiredChild(userElement, "UserNumber")),
        firstName: getValue(getRequiredChild(userElement, "FirstName")),
        lastName: getValue(getRequiredChild(userElement, "LastName")),
        middleName: getValue(getRequiredChild(userElement, "MiddleName")),
        address: getValue(getRequiredChild(userElement, "Address")),
        phone: getValue(getRequiredChild(userElement, "Phone")),
        email: getValue(getRequiredChild(userElement, "Email")),
        login: getRequiredValue(getRequiredChild(userElement, "Login")),
        partnerId: getChild(userElement, "PartnerId") ? getRequiredIntValue(getRequiredChild(userElement, "PartnerId")) : 0,
        birthday: checkZeroBirthday(getRequiredValue(getRequiredChild(userElement, "Birthday"))),
        registrationTime: registrationTimeElement ? toLocalTime(new Date(getValue(registrationTimeElement))) : undefined,
        countryId: getRequiredIntValue(getRequiredChild(userElement, "CountryId")),
        city: cityElement ? getValue(cityElement) : "",
        IdentificationCode: identificationCodeElement ? getValue(identificationCodeElement) : "",
        sessions,
        userRoles: roles
    };
}

export interface LoginResult {
    sessionCode: string;
    user: User;
}

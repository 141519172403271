import { useRestService } from "@/services";
import { onMounted, onUnmounted, ref } from "vue";
import { useBalanceStore } from "@/store/balance-store";
import { isMobile } from "@/utils/browser";

export default {
    name: "GameView",
    setup () {
        const restService = useRestService();
        const currentGameUrl = ref<string>();
        const store = useBalanceStore();
        onMounted(async () => {
            await restService.casinoEnter();
            await restService.closeGameFrame();
            const r = await restService.startGameFrame(store.balance?.casinoBalance?.primaryAccount === undefined &&
            store.balance?.mainBalance?.primaryAccount === undefined);
            if (r.type === "success") {
                if (isMobile()) {
                    window.location.href = r.url;
                } else {
                    currentGameUrl.value = r.url;
                }
            }
        });

        onUnmounted(async () => {
            await restService.casinoLeave();
        });

        return {
            currentGameUrl
        };
    }
};

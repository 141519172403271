<template>
    <Form @submit="onSubmit" v-if="true" v-slot="{ errors }">
        <div class="slots-auth">
            <div class="slots-auth-logo">
                <img class="logo" src="/4444logo.png" alt="4444">
            </div>
            <div class="slots-auth-container">
                <div class="slots-auth-content">
                    <div v-show="!showForm" class="slots-auth-content-info desktop">
                        <h2 class="title">
                            {{$t("brand.name")}}
                        </h2>
                        <p class="description">
                            {{$t("brand.description1")}}<br/>
                            <br/>
                            {{$t("brand.description2")}}
                        </p>
                        <button class="button btn-play" @click="showForm = true">{{$t("registration.registrationButton")}}</button>
                        <button class="button btn-more" type="button" @click="showModal = true">{{$t("login.detailsButton")}}</button>
                    </div>
                    <div v-show="showForm" class="slots-auth-content-form desktop">
                        <h3 class="title">
                            {{$t("registration.title")}}
                        </h3>
                        <div class="form-fields">
                            <div class="form-line" :class="{'label-error': errors.phone || customErrors.phone}">
                                <label for="phone">
                                    {{$t("registration.login")}}
                                </label>
                                <div class="line-wrap">
                                    <div class="select-wrapper">
                                        <div class="icon"><img src="/flags/ua.png" alt=""></div>
                                    </div>
                                    <div class="mobile-wrap">
                                        <input class="num" type="text" :value="phoneCode" readonly>
                                        <Field type="tel" id="phone" name="phone" rules="required|digits:9" @keydown="onPhoneKeyDown"/>
                                        <div class="field-error" v-if="errors.phone || customErrors.phone">{{errors.phone}}{{customErrors.phone}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-line password" :class="{'label-error': errors.password }">
                                <label for="password">
                                    {{$t("registration.password")}}
                                </label>
                                <div class="line-wrap">
                                    <password-field :isConfirm="false" :errors="errors" ></password-field>
                                </div>
                            </div>
                            <div  class="under-fields checkbox-wrapper">
                                <div class="form-line checkbox">
                                    <input type="checkbox" name="check1" id="check1" v-model="ageAgreement">
                                    <label class="label-check" for="check1">
                                        {{$t("registration.ageAgreement")}}
                                    </label>
                                </div>
                                <div class="form-line checkbox">
                                    <input type="checkbox" name="check2" id="check2" v-model="rulesAgreement">
                                    <label class="label-check" for="check2">
                                        <span v-html="$t('registration.rulesAgreement')"></span>
                                    </label>
                                </div>
                                <div class="form-line checkbox">
                                    <input type="checkbox" name="check3" id="check3" v-model="dataAgreement">
                                    <label class="label-check" for="check3">
                                        {{$t("registration.dataAgreement")}}
                                    </label>
                                </div>
                                <button class="button registration" :disabled="isSubmitButtonDisabled">{{$t("registration.registrationButton")}}</button>
                                <button class="button log-in" @click="$router.push({ name: ROUTES.LOGIN.name })">{{$t("registration.loginButton")}}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Form>
    <modal-description v-model:show="showModal" :title="$t('login.detailsTitle')">
        <span v-html="$t('login.details')"></span>
    </modal-description>
</template>

<script lang="ts" src="./registration-view-component.ts"/>

<template>
    <div id="confirm-code-dialog" class="modal site-popup active" data-cy="confirm-code-dialog">
        <div class="site-popup-container">
            <div class="popup-close" @click.prevent="onClose"></div>
            <div class="title">{{$t("webdesktop.confirmCodeDialog.caption")}}</div>
            <div class="content">
                <div class="site-popup-text">
                    {{$t("webdesktop.confirmCodeDialog.text")}}
                </div>
                <div class="site-popup-form">
                    <div class="part">
                        <div class="form-line">
                            <label>{{$t("webdesktop.confirmCodeDialog.inputLabel")}}</label>
                            <input id="confirm-code-input" type="text" v-model="confirmCodeRef" data-cy="confirm-code-input">
                        </div>
                    </div>
                </div>
                <div class="site-popup-buttons">
                    <button id="close-button" class="button button-secondary" type="button" @click="onClose">{{$t("common.forms.close")}}</button>
                    <button id="submit-button" class="button" type="submit" data-cy="confirm-code-dialog-submit-button" @click.prevent="onSubmit()" :class="{disabled: !confirmCodeRef}">{{$t("webdesktop.confirmCodeDialog.submitButton")}}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="./confirm-code-dialog-component.ts" />

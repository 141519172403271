import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "messagebox-container" }
const _hoisted_2 = { class: "messagebox-content" }
const _hoisted_3 = {
  key: 0,
  class: "title"
}
const _hoisted_4 = {
  class: "description",
  "data-testid": "message-text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["messagebox", [_ctx.type]])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.title)
          ? (_openBlock(), _createElementBlock("h3", _hoisted_3, _toDisplayString(_ctx.title), 1))
          : _createCommentVNode("", true),
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.description), 1)
      ]),
      _createElementVNode("button", {
        class: "button button-general",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleClose && _ctx.handleClose(...args)))
      }, _toDisplayString(_ctx.$t("common.forms.close")), 1)
    ])
  ], 2))
}
import VoucherDepositDialog
    from "@/views/deposit-view/voucher-deposit-dialog/VoucherDepositDialog.vue";
import { ref } from "vue";
import { Point } from "@/model/point";
import { useRestService, useSettingsService } from "@/services";

enum ModalDialogs {
    Deposit = "VoucherDepositDialog",
    None = ""
}

export default {
    name: "DepositView",
    components: {
        VoucherDepositDialog
    },
    setup () {
        const activeDialog = ref<ModalDialogs>(ModalDialogs.None);
        const settings = useSettingsService().pointSettings;
        const restService = useRestService();
        const pointList = ref<Point[]>([]);
        restService.getPointList(2, [settings.partnerId]).then(p => {
            pointList.value = p;
        });
        function onModalDialogClose () {
            activeDialog.value = ModalDialogs.None;
        }

        function onModalDialogOpen (dialog: ModalDialogs) {
            activeDialog.value = dialog;
        }

        return {
            ModalDialogs,
            activeDialog,
            pointList,
            onModalDialogClose,
            onModalDialogOpen
        };
    }
};
import { PropType, ref } from "vue";
import { Field } from "vee-validate";

type PasswordErrors = {
    password: string;
    confirmPassword: string;
};

export default {
    name: "PasswordField",
    props: {
        isConfirm: { required: true, type: Boolean },
        errors: { required: true, type: Object as PropType<PasswordErrors> }
    },
    components: {
        Field
    },
    setup () {
        const isPasswordVisible = ref(false);
        function switchPasswordVisible () {
            isPasswordVisible.value = !isPasswordVisible.value;
        }
        return {
            isPasswordVisible,
            switchPasswordVisible
        };
    }
};

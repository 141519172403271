<template>
    <div class="modal site-popup active" id="popup6">
        <div class="site-popup-container">
            <div class="popup-close" @click.prevent="close"></div>
            <div class="title">{{caption}}</div>
            <div class="content">
                <div class="site-popup-text">
                    {{message}}
                </div>
                <div class="site-popup-buttons">
                    <button class="button button-secondary" @click.prevent="onNoButtonClick">{{$t("common.forms.no")}}</button>
                    <a class="button" @click.prevent="onYesButtonClick">{{$t("common.forms.yes")}}</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="./confirmation-dialog-component.ts" />

<template>
    <div class="finances">
        <FinancesTabs/>
        <div class="finances-wrap">
            <div class="filters-line">
                <div class="select-wrap">
                    <label for="period-from">Період з:</label>
                    <input type="date" v-model="startDateRef" @input="dateChanged" id="period-from" name="period-from"  />
                </div>
                <div class="select-wrap">
                    <label for="period-from">до:</label>
                    <input type="date" v-model="endDateRef" @input="dateChanged" id="period-to" name="period-from"  />
                </div>
                <div class="select-wrap items-per-page mob-none">
                    <label >Рядків на сторінці:</label>
                    <select v-model="itemsPerPageRef">
                        <option value="" hidden></option>
                        <option :value="index * 10" :key="index" v-for="index in 5">{{ index * 10 }}</option>
                    </select>
                </div>
            </div>
            <div class="personal-pagination router-pagination mob-none" v-if="totalPages > 0">
                <a @click.prevent="currentPage = 1" class="arrow" :class="{disabled: currentPage === 1}">&lt;&lt;</a>
                <a @click.prevent="currentPage--" class="arrow" :class="{disabled: currentPage === 1}">&lt;</a>
                <ul class="personal-pagination-list">
                    <a @click.prevent="currentPage = index" :class="{active: index === 1}"  v-for="index in totalPages" :key="index">{{index}}</a>
                </ul>
                <a @click.prevent="currentPage++" class="arrow" :class="{disabled: currentPage === totalPages}">&gt;</a>
                <a @click.prevent="currentPage = totalPages" class="arrow" :class="{disabled: currentPage === totalPages}">&gt;&gt;</a>
            </div>
            <table class="personal-table">
                <thead class="personal-table-head">
                <tr>
                    <th>{{$t("finances.cols.date")}}</th>
                    <th>{{$t("finances.operationColumn")}}</th>
                    <th>{{$t("finances.cols.amount")}}</th>
                </tr>
                </thead>
                <tbody class="personal-table-body">
                <tr  v-for="operation of operationsPaged" :key="operation.id">
                    <td><small>{{ $d(operation.operationDate, 'long') }}</small></td>
                    <td><b>{{ $t("operations." + operation.operationType) }}</b></td>
                    <td>
                        {{operation.amount}} {{operation.currency}}
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script src="./deposit-orders-component.ts"/>

<style scoped>

</style>

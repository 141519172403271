<template>
    <Form @submit="onSubmit" v-slot="{ errors, handleSubmit }">
    <div class="profile">
        <div class="profile-cols profile-items">
            <div class="profile-col">
                <div class="item">
                    <div class="profile-head">
                        {{$t("profile.title1")}}
                    </div>
                    <div class="profile-body">
                        <div class="field-wrap">
                            <div class="form-line locked">
                                <label>{{$t("profile.number")}}</label>
                                <input type="text" readonly :value="user.userNumber">
                                <div class="icon">
                                    <img src="/form/icon-lock.png" alt="">
                                </div>
                            </div>
                        </div>
                        <div class="field-wrap">
                            <div class="form-line">
                                <label>{{$t("profile.password")}}</label>
                                <input readonly type="text" value="********">
                                <div class="icon" @click.prevent="currentDialog = 'ChangePasswordDialog'">
                                    <img src="/form/icon-edit.png" alt="">
                                </div>
                            </div>
                            <!-- <div class="field-error">Помилка</div> -->
                        </div>
                        <div class="field-wrap">
                            <div class="form-line locked">
                                <label>{{$t("profile.date")}}</label>
                                <input type="text" readonly :value="$d(user.registrationTime, 'long')">
                                <div class="icon">
                                    <img src="/form/icon-lock.png" alt="">
                                </div>
                            </div>
                        </div>
                        <div class="field-wrap">
                            <div class="form-line">
                                <label>{{$t("profile.id")}}</label>
                                <Field id="login" name="login" :value="user.login" type="text" />
                                <div class="icon">
                                    <img src="form/icon-edit.png" />
                                </div>
                            </div>
                            <div class="field-error" v-if="errors.login || customErrors.login">{{errors.login}}{{customErrors.login}}</div>
                        </div>
                    </div>
                </div>
                <div class="item">
                    <div class="profile-head">
                        {{$t("profile.titleDoc")}}
                    </div>
                    <div class="profile-body">
                        <div class="field-wrap">
                            <div class="form-line">
                                <label>{{$t("profile.docType")}}</label>
                                <select v-model="docType">
                                    <option value="" hidden><label>{{$t("profile.selectDocType")}}</label></option>
                                    <option :value="index" :key="index" v-for="index in 5">{{$t("profile.docTypes."+index)}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="field-wrap">
                            <div class="form-line document">
                                <label>{{$t("profile.file")}}</label>
                                <input type="text" :value="fileToUpload ? fileToUpload.name : $t('profile.fileNotSelected')" readonly>
                                <button class="button button-general" type="button">
                                    {{$t("profile.selectBtn")}}
                                    <input type="file" accept="image/png, image/jpeg" :class="{disabled: requestPending}" @change="selectFileToUpload($event)">
                                </button>
                            </div>
                        </div>
                        <div class="profile-buttons document-buttons">
                            <button class="button button-general" type="button" @click="cancelUpload" :class="{disabled: requestPending}">{{$t("common.forms.cancel")}}</button>
                            <button class="button button-secondary" type="button" :class="{disabled: !docType || !fileToUpload || requestPending}"
                                    @click="uploadFile">{{$t("profile.uploadBtn")}}</button>
                        </div>
                        <p class="text-necessarily">{{ $t("profile.docNecessarily") }}</p>
                    </div>
                </div>
            </div>
            <div class="profile-col">
                <div class="item">
                    <div class="profile-head">
                        {{$t("profile.title2")}}
                    </div>
                    <div class="profile-body">
                        <div class="field-wrap">
                            <div class="form-line disabled locked">
                                <label>{{$t("profile.phone")}}</label>
                                <input readonly type="text" :value="user.phone">
                                <div class="icon">
                                    <img src="/form/icon-lock.png" alt="">
                                </div>
                            </div>
                        </div>
                        <div class="field-wrap">
                            <div class="form-line">
                                <label>{{$t("profile.surname")}}</label>
                                <Field id="lastName" name="lastName" :value="user.lastName" />
                                <div class="icon">
                                    <img src="form/icon-edit.png" />
                                </div>
                            </div>
                            <div class="field-error" v-if="errors.lastName">{{errors.lastName}}</div>
                        </div>
                        <div class="field-wrap">
                            <div class="form-line">
                                <label>{{$t("profile.name")}}</label>
                                <Field id="firstName" name="firstName" :value="user.firstName" />
                                <div class="icon">
                                    <img src="form/icon-edit.png" />
                                </div>
                            </div>
                            <div class="field-error" v-if="errors.firstName">{{errors.firstName}}</div>
                        </div>
                        <div class="field-wrap">
                            <div class="form-line">
                                <label>{{$t("profile.patronymic")}}</label>
                                <Field id="middleName" name="middleName" :value="user.middleName" />
                                <div class="icon">
                                    <img src="form/icon-edit.png" />
                                </div>
                            </div>
                            <div class="field-error" v-if="errors.middleName">{{errors.middleName}}</div>
                        </div>
                        <div class="field-wrap">
                            <div class="form-line date">
                                <label>{{$t("profile.birthday")}}</label>
                                <Field type="date" rules="birthday" id="birthday" name="birthday" :value="user.birthday" :max="maxDate"/>
                                <div class="icon">
                                    <img src="/form/icon-edit.png" />
                                </div>
                            </div>
                            <div class="field-error" v-if="errors.birthday || customErrors.birthday">{{errors.birthday}}{{customErrors.birthday}}</div>
                        </div>
                        <div class="field-wrap" v-for="doc of userContent" :key="doc">
                            <div class="form-line document">
                                <label v-if="doc.docType">{{$t("profile.docTypes." + doc.docType)}}</label>
                                <input type="text" :value="$t('profile.docStatus.' + doc.status)" readonly>
                                <div class="icon">
                                    <img :src="getFileIcon(doc.status)" />
                                </div>
                            </div>
                        </div>
                        <div class="profile-buttons">
                            <button class="button button-secondary" type="submit" @click.stop.prevent="handleSubmit(onSubmit)">Зберегти</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </Form>
    <component :is="currentDialog" @close="onDialogClose" />
</template>

<script src="./profile-view-component.ts"/>

import { Point } from "@/model/point";
import { AbstractMessageHandler } from "@/services/rest/messages/message-handler";
import { XmlRequest } from "@/services/rest/xml-request";
import { getRequiredAttribute, getRequiredChild, getRequiredIntAttribute } from "@/utils/xml-helper-functions";

export class QGe23PointListMessageHandler extends AbstractMessageHandler<Point[]> {
    protected readonly requestType: string = "Q.GE.2.3";
    constructor (readonly pointTypeId: number, readonly partnerList: number[]) {
        super();
    }

    buildRequest (request: XmlRequest) {
        const actionElement = request.addChild(request.body, "query", {
            type: this.requestType
        });
        const filterElement = request.addChild(actionElement, "Filter");
        const pointFilterElement = request.addChild(filterElement, "PointFilter");
        const partnerListElement = request.addChild(pointFilterElement, "PartnerList");
        for (const partner of this.partnerList) {
            request.addChildWithValue(partnerListElement, "PartnerId", partner.toString());
        }
        const pointTypeListElement = request.addChild(pointFilterElement, "PointTypeList");
        request.addChildWithValue(pointTypeListElement, "PointTypeId", this.pointTypeId.toString());
    }

    parseMessageBody (body: Element): Point[] {
        const query = getRequiredChild(body, "query");
        const pointList = getRequiredChild(query, "PointList");
        const result: Point[] = [];
        for (let pointElement = pointList.firstElementChild; pointElement != null; pointElement = pointElement.nextElementSibling) {
            result.push({
                id: getRequiredIntAttribute(pointElement, "Id"),
                name: getRequiredAttribute(pointElement, "name"),
                pointTypeId: getRequiredIntAttribute(pointElement, "pointTypeId"),
                address: getRequiredAttribute(pointElement, "address"),
                cityName: getRequiredAttribute(pointElement, "cityName"),
                partnerId: getRequiredIntAttribute(pointElement, "partnerId"),
                stationId: getRequiredIntAttribute(pointElement, "stationId")
            });
        }
        return result;
    }
}

import { computed, ref } from "vue";
import ProfileViewDialog from "@/views/profile-view/profile-view-dialog/ProfileViewDialog.vue";
import { useUserStore } from "@/store/user-store";
import { Field, Form } from "vee-validate";
import { formatForMaxDate } from "@/utils/time-utils";
import { ProfileUpdateData } from "@/model/user-accounts";
import { useI18n } from "vue-i18n";
import { useRestService } from "@/services";
import { showMessageBox } from "@/components/messagebox/message-box-component";
import { MtlError } from "@/services/rest/errors";
import ChangePasswordDialog from "@/views/profile-view/change-password-dialog/ChangePasswordDialog.vue";
import { DocStatus, UserDocument } from "@/model/user-content";

interface PorfileFormErrors {
    login?: string,
    birthday?: string
}

export default {
    name: "ProfileView",
    components: {
        ProfileViewDialog,
        ChangePasswordDialog,
        Form,
        Field
    },
    setup () {
        const store = useUserStore();
        const i18n = useI18n();
        const restService = useRestService();
        const currentDialog = ref();
        const customErrors = ref<PorfileFormErrors>({
            login: undefined,
            birthday: undefined
        });
        const d = new Date();
        d.setFullYear(d.getFullYear() - 18);
        const maxDate = formatForMaxDate(d);
        const user = computed(() => {
            return store.user;
        });
        const fileToUpload = ref<File>();
        const docType = ref<string>();
        const userContent = ref<UserDocument[]>([]);
        const requestPending = ref(false);

        function onDialogClose () {
            currentDialog.value = undefined;
        }

        restService.getUserContent().then((r) => {
            userContent.value = r.userContent;
        });

        function onSubmit (formData: ProfileUpdateData) {
            const u = user.value;
            if (u) {
                customErrors.value = {
                    login: undefined,
                    birthday: undefined
                };
                restService.updateProfile(formData).then(() => {
                    showMessageBox("success", undefined, i18n.t("profile.success"));
                    store.updateProfile(formData);
                }).catch(e => {
                    if (e instanceof MtlError) {
                        const serverCode = (e as MtlError).code;
                        const message = i18n.t(`errors.mtl.code${serverCode}`);
                        switch (serverCode) {
                            case "1112": {
                                customErrors.value.birthday = message;
                                document.getElementById("birthday")?.focus({ preventScroll: false });
                                break;
                            }
                            case "1154": {
                                customErrors.value.login = message;
                                document.getElementById("login")?.focus({ preventScroll: false });
                                break;
                            }
                            default: {
                                showMessageBox("error", undefined, message);
                            }
                        }
                    } else {
                        showMessageBox("error", undefined, e.toString());
                    }
                });
            }
        }

        function selectFileToUpload ($event: Event) {
            const target = $event.target as HTMLInputElement;
            if (target && target.files) {
                fileToUpload.value = target.files[0];
            }
        }

        function cancelUpload () {
            if (requestPending.value) {
                return;
            }
            fileToUpload.value = undefined;
            docType.value = undefined;
        }

        function uploadFile () {
            if (requestPending.value) {
                return;
            }
            if (docType.value && fileToUpload.value) {
                const reader = new FileReader();
                reader.onload = () => {
                    if (docType.value && fileToUpload.value && reader.result) {
                        requestPending.value = true;
                        restService.sendFile(reader.result.toString(), docType.value, fileToUpload.value.name,
                            fileToUpload.value.type, fileToUpload.value.size).then(() => {
                            if (fileToUpload.value && docType.value) {
                                userContent.value.push({
                                    docType: docType.value,
                                    status: DocStatus.CONTENT_STATUS_FILE_LOADED
                                });
                                fileToUpload.value = undefined;
                                docType.value = undefined;
                                showMessageBox("success", undefined, i18n.t("profile.uploadSuccess"));
                            }
                        }).finally(() => {
                            requestPending.value = false;
                        });
                    }
                };
                reader.readAsDataURL(fileToUpload.value);
            }
        }

        function getFileIcon (status: DocStatus) {
            switch (status) {
                case DocStatus.CONTENT_STATUS_PERSON_DATA_ACCEPTED: return "/icon-check-mark.gif";
                case DocStatus.CONTENT_STATUS_FILE_LOADED: return "/clock-blue.svg";
                default: return "/icon-exclamation.png";
            }
        }
        return {
            user,
            maxDate,
            customErrors,
            currentDialog,
            docType,
            fileToUpload,
            userContent,
            requestPending,
            getFileIcon,
            uploadFile,
            cancelUpload,
            selectFileToUpload,
            onDialogClose,
            onSubmit
        };
    }
};

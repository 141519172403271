import { ref, SetupContext } from "vue";
import { CLOSE_EVENT } from "@/utils/events";
import { useRestService } from "@/services";
import { VoucherSellInfo } from "@/views/deposit-view/voucher-deposit-dialog/voucher";
import { DialogState } from "@/views/deposit-view/voucher-deposit-dialog/dialog-state";

export default {
    name: "VoucherDepositDialog",
    emits: [CLOSE_EVENT],
    setup (props:[], context: SetupContext) {
        const voucherNumber = ref<string>();
        const restService = useRestService();
        const voucherRef = ref<VoucherSellInfo>();
        const dialogState = ref<DialogState>(DialogState.InputAmount);
        const requestPending = ref(false);

        function close () {
            context.emit(CLOSE_EVENT);
        }

        function submit () {
            if (dialogState.value === DialogState.Finish) {
                close();
            }
            if (dialogState.value === DialogState.InputAmount && voucherNumber.value) {
                restService.findVoucher(voucherNumber.value).then(voucher => {
                    voucherRef.value = voucher;
                    dialogState.value = DialogState.ConfirmationType;
                });
            }
            if (dialogState.value === DialogState.ConfirmationType && voucherNumber.value) {
                requestPending.value = true;
                restService.voucherDeposit(voucherNumber.value).then(() => {
                    dialogState.value = DialogState.Finish;
                    requestPending.value = false;
                }).catch(() => {
                    requestPending.value = false;
                });
            }
        }
        return {
            voucherNumber,
            voucherRef,
            dialogState,
            requestPending,
            close,
            submit
        };
    }
};

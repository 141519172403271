import { Form, Field } from "vee-validate";
import CountrySelect from "@/components/country-select/CountrySelect.vue";
import PasswordField from "@/components/password-field/PasswordField.vue";
import { computed, ref } from "vue";
import { countries } from "@/model/consts/countries";
import { ROUTES } from "@/router/route-consts";
import { onNaturalNumberKeyFilter } from "@/utils/form-validation";
import ModalDescription from "@/components/modal/ModalDescription.vue";
import { useRestService } from "@/services";
import { useI18n } from "vue-i18n";
import { MtlError } from "@/services/rest/errors";
import { useRouter } from "vue-router";
import { showMessageBox } from "@/components/messagebox/message-box-component";

interface RegistrationFormErrors {
    phone: string | undefined,
    password: string | undefined
}

interface RegistrationData {
    phone: string;
    password: string;
}

export default {
    components: {
        Form, Field, CountrySelect, PasswordField, ModalDescription
    },
    name: "RegistrationView",
    setup () {
        const showForm = ref(false);
        const showModal = ref(false);
        const countryId = ref<number>(217);
        const ageAgreement = ref(false);
        const rulesAgreement = ref(false);
        const dataAgreement = ref(false);
        const restService = useRestService();
        const isRequestPending = ref(false);
        const i18n = useI18n();
        const router = useRouter();
        const isSubmitButtonDisabled = computed(() => !(ageAgreement.value && rulesAgreement.value && dataAgreement.value));

        const phoneCode = computed(() => {
            return countryId.value ? countries.getById(countryId.value)?.phoneCode : "";
        });
        const customErrors = ref<RegistrationFormErrors>({
            phone: undefined,
            password: undefined
        });

        function onSubmit (regData: RegistrationData) {
            customErrors.value = {
                phone: undefined,
                password: undefined
            };
            isRequestPending.value = true;
            restService.registerUser(phoneCode.value + regData.phone, regData.password)
                .then(() => {
                    isRequestPending.value = false;
                    showMessageBox("success", undefined, i18n.t("registration.success"), () => {
                        router.push({ name: ROUTES.LOGIN.name }).then();
                    });
                }).catch(e => {
                    isRequestPending.value = false;
                    if (e instanceof MtlError) {
                        const serverCode = (e as MtlError).code;
                        const message = i18n.t(`errors.mtl.code${serverCode}`);
                        switch (serverCode) {
                            case "1118":
                            case "1115": {
                                customErrors.value.phone = message;
                                document.getElementById("phone")?.focus({ preventScroll: false });
                                break;
                            }
                        }
                    }
                });
        }

        return {
            showModal,
            showForm,
            countryId,
            phoneCode,
            customErrors,
            ageAgreement,
            rulesAgreement,
            dataAgreement,
            isSubmitButtonDisabled,
            isRequestPending,

            ROUTES,
            onSubmit,
            onPhoneKeyDown: onNaturalNumberKeyFilter
        };
    }
};

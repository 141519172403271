<template>
    <footer class="footer">
        <div class="footer-cols">
            <div class="col">
                <div class="title">
                    КОРИСНІ ПОСИЛАННЯ
                </div>
                <ul class="footer-menu">
                    <li><a href="https://4444.ua/vidpovidalna-hra/" target="_blank">ВІДПОВІДАЛЬНА ГРА</a></li>
                    <li><a href="https://4444.ua/nashi-zaly/" target="_blank">НАШІ ЗАЛИ</a></li>
                    <li><a href="https://4444.ua/hravtsiam/" target="_blank">ГРАВЦЯМ</a></li>
                    <li><a href="https://4444.ua/dlia-biznesu/" target="_blank">ДЛЯ БІЗНЕСУ</a></li>
                    <li><a href="https://4444.ua/contacts/" target="_blank">КОНТАКТИ</a></li>
                    <li><a href="https://4444.ua/pravova-informatsiia/" target="_blank">ПРАВОВА ІНФОРМАЦІЯ</a></li>
                </ul>
            </div>
            <div class="col">
                <div class="title">
                    КОНТАКТНА ІНФОРМАЦІЯ
                </div>
                <p>
                    ТОВ "ВОЛНА 4444"
                </p>
                <p>
                    01023, М. КИЇВ, ПЛ. СПОРТИВНА, БУД. 1-А
                </p>
                <p>
                    INFO@4444.UA
                </p>
                <ul class="footer-social">
                    <li>
                        <a href="https://www.facebook.com/4444-SLOTS-106516671501123">
                            <img src="/social/facebook.png" alt="">
                        </a>
                    </li>
                    <li>
                        <a href="https://www.instagram.com/4444slots">
                            <img src="/social/instagram.png" alt="">
                        </a>
                    </li>
                    <li>
                        <a href="https://t.me/SLOTS_4444">
                            <img src="/social/telegram.png" alt="">
                        </a>
                    </li>
                </ul>
            </div>
            <div class="col">
                <div class="title">
                    ДОДАТКОВА ІНФОРМАЦІЯ
                </div>
                <p>
                    Приймати участь в азартних іграх можуть особи, які досягли 21-річного віку та не перебувають у Реєстрі осіб, яким обмежено доступ до гральних закладів та/або участь в азартних іграх.<br/>
                    <br/>
                    Вхід до залу гральних автоматів можливий лише після проходження процедури ідентифікації та верифікації.
                </p>
            </div>
            <div class="col">
                <div class="title">
                    ВІДПОВІДАЛЬНА ГРА
                </div>
                <p>
                    Грайте безпечно та відповідально, саме так можна отримати задоволення від азартної гри.<br/>
                    <br/>
                    Якщо ви відчуваєте, що вам або вашим близьким потрібна допомога, інформація чи порада про відповідальну гру, будь ласка, звертайтесь на гарячу лінію<br/> 0 (800) 50 51 51.
                </p>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: "FooterMain"
};
</script>

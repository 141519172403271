import {
    getChild,
    getRequiredAttribute,
    getRequiredChild,
    getRequiredFloatAttribute,
    getRequiredIntAttribute
} from "@/utils/xml-helper-functions";
import { Balance, UserBalance } from "@/model/user-balance";
import { UserAccount } from "@/model/user-accounts";
import { XmlRequest } from "@/services/rest/xml-request";
import { AbstractMessageHandler } from "@/services/rest/messages/message-handler";
import { NotAuthorizedError } from "@/services/rest/errors";

export class QUs112ExtendedBalance extends AbstractMessageHandler<UserBalance | undefined> {
    protected readonly requestType: string = "Q.US.1.12";

    constructor () {
        super();
    }

    buildRequest (request: XmlRequest) {
        const actionElement = request.addChild(request.body, "query", {
            type: this.requestType
        });
        const selectorElement = request.addChild(actionElement, "Selector");
        const userFilter = request.addChild(selectorElement, "SelectObject", {
            class: "ps.user.User"
        });
        request.addChild(userFilter, "SelectObject", {
            class: "ps.user.UserAccount",
            allFields: "true"
        });
        request.addChild(userFilter, "SelectObject", {
            class: "ps.user.UserCasinoAccount",
            allFields: "true"
        });
    }

    private parseAccount (userAccountElement: Element) {
        return {
            accountId: getRequiredIntAttribute(userAccountElement, "Id"),
            accountNumber: getRequiredAttribute(userAccountElement, "accountNumber"),
            currencyId: getRequiredIntAttribute(userAccountElement, "currencyId"),
            currencyMark: getRequiredAttribute(userAccountElement, "currencyMark"),
            currencyValue: getRequiredFloatAttribute(userAccountElement, "currencyValue"),
            isBlocked: getRequiredIntAttribute(userAccountElement, "isBlocked") === 1,
            isBonus: getRequiredIntAttribute(userAccountElement, "isBonus") === 1,
            isUsed: getRequiredIntAttribute(userAccountElement, "isUsed") === 1,
            sumAccount: getRequiredFloatAttribute(userAccountElement, "sumAccount"),
            sumCaptive: getRequiredFloatAttribute(userAccountElement, "sumCaptive"),
            sumStake: getRequiredFloatAttribute(userAccountElement, "sumStake"),
            accountMark: getRequiredAttribute(userAccountElement, "accountMark")
        };
    }

    parseAccounts (accountListElement: Element): Balance {
        let primaryAccount: UserAccount | undefined;
        let bonusAccount: UserAccount | undefined;
        if (accountListElement !== undefined) {
            for (let userAccountElement = accountListElement.firstElementChild; userAccountElement !== null; userAccountElement = userAccountElement.nextElementSibling) {
                const accountMark = getRequiredAttribute(userAccountElement, "accountMark");
                if (accountMark === "USER_ACCOUNT_PRIMARY") {
                    primaryAccount = this.parseAccount(userAccountElement);
                } else if (accountMark === "USER_ACCOUNT_BONUS" || accountMark === "USER_ACCOUNT_CASINO_BONUS") {
                    bonusAccount = this.parseAccount(userAccountElement);
                }
            }
        }
        return {
            primaryAccount,
            bonusAccount
        };
    }

    // eslint-disable-next-line
    parseMessageBody (body: Element, _head: Element): UserBalance | undefined {
        const action = getRequiredChild(body, "query");
        const serverCode = getRequiredAttribute(action, "servercode");
        if (serverCode === "1001" || serverCode === "1313") {
            throw new NotAuthorizedError();
        }
        let mainBalance: Balance | undefined;
        let casinoBalance: Balance | undefined;
        if (serverCode === "1190") {
            const userElement = getChild(action, "US");
            if (userElement) {
                const accountListElement = getRequiredChild(userElement, "UserAccountList");
                mainBalance = this.parseAccounts(accountListElement);
                const casinoAccountListElement = getChild(userElement, "UserCasinoAccountList");
                casinoBalance = casinoAccountListElement
                    ? this.parseAccounts(casinoAccountListElement)
                    : {
                        primaryAccount: undefined,
                        bonusAccount: undefined
                    };
            }
        }
        const result: UserBalance = {
            mainBalance,
            casinoBalance
        };
        return result;
    }
}

import {
    getRequiredAttribute,
    getRequiredChild
} from "@/utils/xml-helper-functions";
import { AbstractMessageHandler } from "@/services/rest/messages/message-handler";
import { XmlRequest } from "@/services/rest/xml-request";
import { MtlError } from "@/services/rest/errors";

export class AUs23RegistrationMessageHandler extends AbstractMessageHandler<void> {
    protected readonly requestType: string = "A.US.2.3";
    constructor (readonly phoneNumber: string, readonly password: string) {
        super();
    }

    buildRequest (request: XmlRequest) {
        const actionElement = request.addChild(request.body, "action", {
            type: this.requestType
        });
        request.addChildWithValue(actionElement, "Phone", this.phoneNumber);
        request.addChildWithValue(actionElement, "Password", this.password);
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    parseMessageBody (body: Element, _head: Element) {
        const action = getRequiredChild(body, "action");
        const serverCode = getRequiredAttribute(action, "servercode");
        if (serverCode !== "1100") {
            throw new MtlError(serverCode);
        }
    }
}
